.tos-area {
    .tos-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 3rem;
    }
    .tos-content {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5rem;
    }
    .tos-content-weak {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5rem;
        color: #aaa;
    }
}