.profile .hipscan .profile-form .username-input, .profile .hipscan .profile-form .email-input, .profile .hipscan .profile-form .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%; }
  .profile .hipscan .profile-form .username-input input, .profile .hipscan .profile-form .email-input input, .profile .hipscan .profile-form .input-field .common-input input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }

.profile .mob-hipscan .profile-form .username-input, .profile .mob-hipscan .profile-form .email-input, .profile .mob-hipscan .profile-form .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .profile .mob-hipscan .profile-form .username-input input, .profile .mob-hipscan .profile-form .email-input input, .profile .mob-hipscan .profile-form .input-field .common-input input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    border: 1px solid #414042;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.profile .hipscan {
  height: 670px !important; }
  .profile .hipscan .profile-form .error-message {
    color: red;
    margin-top: 5px;
    padding: 0 20px;
    margin-bottom: 0; }
  .profile .hipscan .profile-form .username-input {
    padding: 30px 0;
    display: block !important; }
  .profile .hipscan .profile-form .email-input {
    padding-bottom: 30px;
    display: block; }
  .profile .hipscan .profile-form .input-field {
    justify-content: space-between;
    display: flex;
    padding-bottom: 30px; }
    .profile .hipscan .profile-form .input-field .common-input {
      width: 47%;
      display: block !important; }
  .profile .hipscan .profile-form .return p {
    color: #f78e1e;
    cursor: pointer; }
  .profile .hipscan .profile-form .btns {
    padding-top: 70px; }
    .profile .hipscan .profile-form .btns .contact-info-btn button {
      background-color: #f36e21 !important; }
    .profile .hipscan .profile-form .btns button {
      width: 230px !important;
      height: 56px !important; }
  .profile .hipscan .upgrade-plan {
    padding: 120px 50px;
    text-align: center;
    color: white;
    background-color: #f78e1e !important; }
    .profile .hipscan .upgrade-plan h1 {
      font-weight: bold;
      padding: 30px 60px;
      font-size: 60px;
      color: white; }
      .profile .hipscan .upgrade-plan h1::first-letter {
        text-transform: uppercase; }
    .profile .hipscan .upgrade-plan .upgrade-link {
      font-size: 0.9rem;
      padding-bottom: 30px; }
      .profile .hipscan .upgrade-plan .upgrade-link .gotoUpgrade {
        text-decoration: none; }
      .profile .hipscan .upgrade-plan .upgrade-link span {
        color: white;
        font-weight: bold; }
    .profile .hipscan .upgrade-plan .change-plan-btn button {
      padding: 10px 50px;
      color: #f36e21;
      background-color: white;
      border: none; }

.profile .mob-hipscan .profile-form .username-input {
  padding: 5px 0; }

.profile .mob-hipscan .profile-form .email-input {
  padding-bottom: 5px;
  display: block; }

.profile .mob-hipscan .profile-form .input-field .common-input {
  padding: 5px 0; }

.profile .mob-hipscan .profile-form .btns button {
  padding: 0.5rem 1rem !important; }
