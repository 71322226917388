.profile-preview {
  height: 100%;
  overflow-y: hidden;
 }

.profile-preview .profile {
  width: 100%;
  height: 100vh;
  padding: 12px;
  position: relative;
  background-color: #161617; }

.profile-preview .profile .background {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  margin: -12px;
  border-radius: 32px;
  opacity: 0.1;
  z-index: 22;
  pointer-events: none; }

.profile-preview .profile .profile-photo {
  height: fit-content;
  display: flex; }

.profile-preview .profile .profile-photo img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  /* border: 2px solid #4193B9; */
  justify-content: center;
  margin: auto;
  margin-top: 30px; }

.profile-preview .profile .display-name {
  height: auto; }

.profile-preview .profile .display-name p {
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 5px 0px;
  margin-bottom: 0px;
  font-weight: bold; }

.action-buttons .action-btns {
  /* background-color: #4193B9; */
  margin-bottom: 10px;
  color: #fff !important;
  font-weight: bold;
  background-color: rgb(65, 147, 185);
  width: 85%; }

.profile-preview .profile .display-name-pre {
  font-size: 15px;
  color: white;
  text-align: center;
  padding: 5px 0px;
  margin: 10px; }

.profile-preview .profile .display-name-pre p {
  margin: 0 }

.profile-preview .profile .display-name-pre .title-pre {
  margin-top: 0; }

.profile-preview .profile .display-name-pre .name {
  font-weight: bold;
  font-size: 8vw;
  margin: 5px; }

@media screen and (min-width: 600px) {
  .profile-preview .profile .display-name-pre .name {
    font-size: 2.3vw; }

  .profile-preview .profile .profile-photo {
    height: fit-content; }

  .profile-preview .profile .profile-photo img {
    width: 16vh;
    height: 16vh; }

  .profile-preview .profile .social-icons a svg {
    width: auto;
    height: auto;
  }

  .action-buttons .action-btns {
    width: 15rem; }
}    

.profile-preview .profile .social-icons {
  display: block;
  text-align: center;
  padding: 5px 10px; }

.profile-preview .profile .social-icons a {
  color: #212529; }

.profile-preview .profile .social-icons a svg {
  z-index: 33;
  margin: 0 18px; 
  width: 25px;
  height: 25px;
}

.profile-preview .profile .social-icons a svg.website {
  background: rgb(65, 147, 185);
  padding: 5px;
  border-radius: 50%; }

.profile-preview .profile .social-icons a svg.facebook {
  background: rgb(65, 147, 185);
  padding: 5px;
  border-radius: 50%; }

.profile-preview .profile .social-icons a svg.twitter {
  background: rgb(65, 147, 185);
  padding: 5px;
  border-radius: 50%; }

.profile-preview .profile .social-icons a svg.instagram {
  background: rgb(65, 147, 185);
  padding: 5px;
  border-radius: 50%; }

.profile-preview .profile .description {
  padding: 14px;
  overflow-y: auto; }

.profile-preview .profile .description p {
  text-align: center;
  color: white;
  font-size: 9px;
  word-break: break-all; }

.profile-preview .profile .contact-info p {
  color: #f78e1e;
  font-size: 11px;
  margin-bottom: 5px;
  text-align: center;
  padding-top: 5px; }

.profile-preview .profile .contact-info h5 {
  color: #f78e1e;
  font-size: 11px;
  margin-bottom: 5px;
  text-align: center; }

.profile-preview .profile .video-screen {
  background: #161617;
  height: 22%;
  display: flex; }

.profile-preview .profile .video-screen .play {
  color: #f36e21;
  margin: auto;
  text-align: center; }

.profile-preview .profile .phone-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 24px); }

.profile-preview .profile .phone-footer p {
  font-size: 7px;
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
  padding-right: 6px;
  letter-spacing: 1px; }

.profile-preview .profile .phone-footer img {
  width: 60px;
  height: 16px; }
