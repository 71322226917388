.plans-page .plans-content .plans-title-container {
  text-align: center; }
  .plans-page .plans-content .plans-title-container .title {
    padding: 10px 10% 0;
    font-size: 50px;
    color: #414042;
    font-weight: 900;
    margin-bottom: 1rem; }
  .plans-page .plans-content .plans-title-container h2 {
    padding: 0 10% 20px;
    font-size: 50px;
    color: #414042;
    font-weight: 600;
    margin-bottom: 1rem; }
  .plans-page .plans-content .plans-title-container .description {
    margin-top: 24px;
    color: #f36e21;
    padding: 0 10%;
    font-weight: 400;
    font-size: 21px; }

.plans-page .plans-content .trialPlan {
  padding: 0 10% 20px;
  font-size: 1.4rem;
  color: #414042;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  color: #f36e21; }

.plans-page .plans-content .plans-pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px; }
  .plans-page .plans-content .plans-pricing .pricing-section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 2px dashed #a8a9a9;
    padding-bottom: 20px;
    transition: all 0.5s;
    z-index: 10;
    position: relative; }
    .plans-page .plans-content .plans-pricing .pricing-section .most-popular {
      height: 55px;
      font-weight: 700;
      transform: rotate(-45deg);
      color: #fff;
      left: -17%;
      top: 2.3%;
      clip-path: polygon(29% 0, 5% 100%, 100% 100%, 100% 119%, 71% 0);
      font-size: 15px;
      line-height: 3.5;
      margin-bottom: 20px;
      padding: 0 50px;
      position: absolute;
      background-color: #f78e1e; }
    .plans-page .plans-content .plans-pricing .pricing-section h1 {
      font-weight: 900;
      font-size: 2.5rem;
      padding: 40px 0px 20px 0px; }
    .plans-page .plans-content .plans-pricing .pricing-section .pricing-section-content {
      color: #f78e1e;
      font-weight: 500; }
    .plans-page .plans-content .plans-pricing .pricing-section .pricing-btn {
      width: 90%;
      padding: 20px 10px;
      border-radius: 9px;
      font-size: 17px;
      background-color: #f36e21;
      font-weight: 700;
      letter-spacing: 2px;
      border: none;
      margin: 30px auto; }
      .plans-page .plans-content .plans-pricing .pricing-section .pricing-btn span {
        font-weight: 500;
        font-size: 15px; }
    .plans-page .plans-content .plans-pricing .pricing-section .pricing-btn.disable {
      background-color: #a8a9a9 !important; }
    .plans-page .plans-content .plans-pricing .pricing-section .current-plan {
      margin-bottom: 10px;
      cursor: pointer; }
    .plans-page .plans-content .plans-pricing .pricing-section .current-plan-free {
      margin-bottom: 30px; }
    .plans-page .plans-content .plans-pricing .pricing-section .bottom-btn {
      margin-top: auto;
      text-align: center; }
      .plans-page .plans-content .plans-pricing .pricing-section .bottom-btn .pricing-btn.month {
        background-color: #f78e1e;
        margin-bottom: 10px; }
      .plans-page .plans-content .plans-pricing .pricing-section .bottom-btn .pricing-btn.annual {
        margin-top: 10px; }
    .plans-page .plans-content .plans-pricing .pricing-section .dynamic {
      padding: 15px 0; }
      .plans-page .plans-content .plans-pricing .pricing-section .dynamic .dynamic-title {
        font-size: 17px;
        margin-bottom: 0; }
      .plans-page .plans-content .plans-pricing .pricing-section .dynamic .dynamic-title.pro {
        color: #f78e1e;
        font-weight: 600; }
        .plans-page .plans-content .plans-pricing .pricing-section .dynamic .dynamic-title.pro svg {
          margin-bottom: 5px; }
      .plans-page .plans-content .plans-pricing .pricing-section .dynamic .dynamic-content {
        font-size: 14px;
        margin-bottom: 0;
        padding-left: 8px;
        padding-right: 8px; }
    .plans-page .plans-content .plans-pricing .pricing-section .pricing-section-title {
      margin-bottom: 0;
      padding: 12px 0;
      font-size: 16px; }
    .plans-page .plans-content .plans-pricing .pricing-section .pricing-section-addition {
      margin-bottom: 0;
      padding: 12px 0;
      font-size: 17px;
      font-weight: bold; }
  .plans-page .plans-content .plans-pricing .pricing-section:hover {
    transform: scale(1.03);
    transition: all 0.5s;
    background-color: #fff;
    z-index: 100;
    box-shadow: 0 0 10px gray;
    border: 0; }
  .plans-page .plans-content .plans-pricing .pricing-section.center {
    border-left: 0;
    border-right: 0; }

.plans-page .plans-content .plans-enterprise {
  color: #fff;
  margin-top: 50px;
  text-align: center;
  background-image: url("../../assets/images/contact.png");
  background-position: center bottom;
  background-repeat: no-repeat; }
  .plans-page .plans-content .plans-enterprise .bg-color {
    padding: 50px 0;
    background-color: #000000c9; }
  .plans-page .plans-content .plans-enterprise h1 {
    font-weight: 700;
    margin-bottom: 20px;
    color: #fff; }
  .plans-page .plans-content .plans-enterprise .contact-btn {
    width: 30%;
    padding: 20px 10px;
    border-radius: 9px;
    font-size: 17px;
    background-color: #f36e21;
    font-weight: 700;
    letter-spacing: 2px;
    border: none;
    margin: 30px 0 10px 0; }

.plans-page .plans-content .plans-customers {
  padding: 100px 0;
  text-align: center;
  color: #414042; }
  .plans-page .plans-content .plans-customers h1 {
    font-weight: 700;
    margin-bottom: 50px; }
  .plans-page .plans-content .plans-customers .customer-imgs {
    display: flex; }
    .plans-page .plans-content .plans-customers .customer-imgs .image-container {
      display: flex;
      align-items: center; }
      .plans-page .plans-content .plans-customers .customer-imgs .image-container .customer-image {
        width: 90%; }

.plans-page .plans-content .faqs {
  padding: 100px 0;
  color: #fff;
  background-color: #f36e21; }
  .plans-page .plans-content .faqs h1 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 70px;
    color: #fff; }
  .plans-page .plans-content .faqs .question-answer {
    padding: 30px 0;
    border-bottom: 1px solid #fff; }
    .plans-page .plans-content .faqs .question-answer .question {
      font-weight: 700;
      color: #fff; }
      .plans-page .plans-content .faqs .question-answer .question svg {
        float: right;
        cursor: pointer; }
    .plans-page .plans-content .faqs .question-answer .answer {
      padding-top: 20px;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 17px; }

@media only screen and (max-width: 1200px) {
  .plans-page .plans-content .plans-title-container .description {
    font-size: 17px; }
  .plans-page .plans-content .pricing-section h1 {
    font-size: 2em !important; }
  .plans-page .plans-content .plans-pricing .most-popular {
    left: -20% !important; } }

@media only screen and (max-width: 992px) {
  .plans-page .plans-content .plans-pricing .most-popular {
    height: 40px !important;
    left: -15.1% !important;
    top: 1.8% !important;
    padding: 0 40px !important;
    font-size: 12px !important;
    clip-path: polygon(22% 0, 0% 100%, 100% 100%, 100% 129%, 72% 0) !important; } }

@media only screen and (max-width: 767px) {
  .plans-page .plans-content .plans-title-container h1 {
    font-size: 30px !important; }
  .plans-page .plans-content .plans-title-container p {
    padding: 0 40px;
    font-size: 15px !important; }
  .plans-page .plans-content .plans-pricing .pricing-section {
    flex: auto !important;
    margin-bottom: 20px; }
  .plans-page .plans-content .plans-pricing .pricing-section.center {
    border-left: 2px dashed #a8a9a9;
    border-right: 2px dashed #a8a9a9; }
  .plans-page .plans-content .plans-pricing .most-popular {
    clip-path: polygon(22% 0, 0% 100%, 100% 100%, 100% 140%, 71% 0) !important;
    left: -10% !important; }
  .plans-page .plans-content .plans-enterprise button {
    width: 50% !important; }
  .plans-page .plans-content .plans-customers h1 {
    font-size: 30px; }
  .plans-page .plans-content .faqs h1 {
    font-size: 30px; }
  .plans-page .plans-content .faqs h4 {
    font-size: 18px; }
  .plans-page .plans-content .faqs p {
    font-size: 14px !important; } }
