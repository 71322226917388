.phone-screen-mock {
  background-color: black;
  width: 100%;
  margin: auto;
  height: 100vh;
  opacity: 0.9;
  max-width: 415px;
  max-height: 823px; }
  .phone-screen-mock .profile {
    width: 100%;
    height: 100%;
    padding: 12px;
    position: relative; }
    .phone-screen-mock .profile .background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: -12px;
      opacity: 0.1;
      z-index: 22;
      pointer-events: none; }
    .phone-screen-mock .profile .profile-photo {
      height: 30%;
      display: flex; }
      .phone-screen-mock .profile .profile-photo img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #f36e21;
        justify-content: center;
        margin: auto;
        margin-top: 25px; }
    .phone-screen-mock .profile .display-name {
      height: 40px;
      margin-top: 10px; }
      .phone-screen-mock .profile .display-name p {
        font-size: 14px;
        color: white;
        text-align: center;
        padding: 5px 0px;
        margin-bottom: 0px; }
    .phone-screen-mock .profile .social-icons {
      display: block;
      text-align: center;
      padding: 5px 10px; }
      .phone-screen-mock .profile .social-icons a {
        color: #212529; }
        .phone-screen-mock .profile .social-icons a svg {
          margin: 0 18px; }
          .phone-screen-mock .profile .social-icons a svg.social {
            background: #f78e1e;
            padding: 5px;
            border-radius: 50%; }
    .phone-screen-mock .profile .description {
      height: 18%;
      padding: 14px; }
      .phone-screen-mock .profile .description p {
        text-align: center;
        color: white;
        font-size: 10px;
        word-break: break-all; }
    .phone-screen-mock .profile .contact-info p {
      color: #f78e1e;
      font-size: 13px;
      margin-bottom: 5px;
      text-align: center;
      padding-top: 5px; }
    .phone-screen-mock .profile .contact-info h5 {
      color: #f78e1e;
      font-size: 13px;
      margin-bottom: 5px;
      text-align: center; }
    .phone-screen-mock .profile .video-screen {
      background: #161617;
      height: 22%;
      display: flex;
      width: 90%;
      margin: auto; }
      .phone-screen-mock .profile .video-screen .play {
        color: #f36e21;
        margin: auto; }

.product-phone-screen {
  background-color: #efefef;
  width: 100%;
  margin: auto;
  height: 100vh;
  max-width: 415px;
  max-height: 823px; }
  .product-phone-screen .product-phone {
    width: 100%;
    height: 100%;
    position: relative; }
    .product-phone-screen .product-phone .product-photo {
      height: 30%;
      display: flex; }
      .product-phone-screen .product-phone .product-photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        justify-content: center;
        margin: auto; }
    .product-phone-screen .product-phone .product-name {
      height: 40px;
      background-color: white;
      align-items: center;
      justify-content: center;
      display: flex; }
      .product-phone-screen .product-phone .product-name p {
        font-size: 14px;
        color: #f36e21;
        font-weight: bold;
        text-align: center;
        padding: 5px 0px 0px 0px;
        margin-bottom: 0px; }
    .product-phone-screen .product-phone .price {
      height: 40px;
      background-color: white;
      align-items: center;
      justify-content: center;
      display: flex;
      padding-bottom: 10px; }
      .product-phone-screen .product-phone .price h1 {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 2px;
        margin-bottom: 0px; }
    .product-phone-screen .product-phone .description {
      height: 22%;
      padding: 14px;
      background-color: #efefef;
      margin: 0px 3px; }
      .product-phone-screen .product-phone .description p {
        color: black;
        font-size: 12px;
        word-break: break-all;
        text-align: center; }
    .product-phone-screen .product-phone .video-screen {
      background: #161617;
      height: 23%;
      width: 85%;
      margin: auto;
      display: flex; }
      .product-phone-screen .product-phone .video-screen .play {
        color: #f78e1e;
        margin: auto; }
    .product-phone-screen .product-phone .phone-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
      .product-phone-screen .product-phone .phone-footer p {
        font-size: 9px;
        font-weight: bold;
        color: black;
        margin-bottom: 0px;
        padding-right: 6px;
        letter-spacing: 1px; }
      .product-phone-screen .product-phone .phone-footer img {
        width: 65px;
        height: 20px; }

.home-phone {
  max-width: 415px;
  max-height: 823px;
  margin: auto;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  overflow: auto; }
  .home-phone .menu {
    width: 100%;
    height: 100%;
    padding: 12px;
    position: relative;
    border: solid 1px #ccc; }
    .home-phone .menu .product-details {
      margin: -12px;
      margin-top: 100px;
      height: calc(100% - 119px); }
      .home-phone .menu .product-details .add-to-cart {
        position: absolute;
        width: 100%;
        background: white;
        bottom: 0;
        padding: 10px 20px;
        border-top: solid 2px #ccc; }
        .home-phone .menu .product-details .add-to-cart .cart-button {
          background: #f36e21;
          color: white;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          border-radius: 4px;
          cursor: pointer; }
      .home-phone .menu .product-details .detail-container {
        background-color: #eee;
        height: 100%; }
        .home-phone .menu .product-details .detail-container .header {
          padding: 20px; }
          .home-phone .menu .product-details .detail-container .header p {
            text-align: left; }
            .home-phone .menu .product-details .detail-container .header p:first-child {
              font-size: 16px;
              font-weight: bold; }
            .home-phone .menu .product-details .detail-container .header p:last-child {
              color: black; }
        .home-phone .menu .product-details .detail-container .mod-list .mod {
          background: white;
          border-bottom: solid 2px #ccc;
          padding: 20px; }
          .home-phone .menu .product-details .detail-container .mod-list .mod:first-child {
            font-size: 16px;
            font-weight: bold; }
          .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* When the checkbox is checked, add a blue background */
            /* Create the checkmark/indicator (hidden when not checked) */
            /* Show the checkmark when checked */
            /* Style the checkmark/indicator */ }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) label {
              min-width: 20%; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container {
              display: block;
              position: relative;
              padding-left: 35px;
              cursor: pointer;
              font-size: 16px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none; }
              .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container:hover input ~ .checkmark {
              background-color: #ccc; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input:checked ~ .checkmark {
              background-color: #f36e21; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .checkmark:after {
              content: "";
              position: absolute;
              display: none; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input:checked ~ .checkmark:after {
              display: block; }
            .home-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container .checkmark:after {
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
    .home-phone .menu .go-back {
      color: white;
      font-size: 40px;
      line-height: 0.5;
      cursor: pointer; }
    .home-phone .menu .logo-container {
      display: flex; }
      .home-phone .menu .logo-container .menu-photo img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #f36e21;
        justify-content: center;
        margin: auto;
        margin-left: 20px; }
      .home-phone .menu .logo-container .menu-info {
        justify-content: center;
        align-items: center;
        padding-left: 20px; }
        .home-phone .menu .logo-container .menu-info p {
          color: white; }
          .home-phone .menu .logo-container .menu-info p.menu-title {
            margin-top: 25px;
            font-weight: bold; }
    .home-phone .menu .categories-container {
      background: white;
      margin: 0 -12px;
      border: solid 1px #ccc;
      margin-top: 20px; }
      .home-phone .menu .categories-container .category-item .category-title {
        padding: 20px;
        border-bottom: solid 1px #ccc; }
        .home-phone .menu .categories-container .category-item .category-title span {
          font-weight: bold; }
      .home-phone .menu .categories-container .category-item .product-list .product-item {
        padding: 20px;
        display: flex;
        border-bottom: solid 1px #ccc; }
        .home-phone .menu .categories-container .category-item .product-list .product-item .product-image img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          cursor: pointer;
          object-fit: cover; }
        .home-phone .menu .categories-container .category-item .product-list .product-item .product-info {
          padding-left: 20px; }
          .home-phone .menu .categories-container .category-item .product-list .product-item .product-info p {
            text-align: left;
            color: black; }
            .home-phone .menu .categories-container .category-item .product-list .product-item .product-info p.product-name {
              font-weight: bold;
              cursor: pointer; }
            .home-phone .menu .categories-container .category-item .product-list .product-item .product-info p.product-desc {
              font-size: 12px; }
      .home-phone .menu .categories-container .category-item .category-separator {
        height: 20px;
        background: #eee; }
    .home-phone .menu .background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: -12px;
      opacity: 0.1;
      z-index: -3; }
    .home-phone .menu .menu-photo {
      height: 30%;
      display: flex; }
      .home-phone .menu .menu-photo img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #f36e21;
        justify-content: center;
        margin: auto;
        margin-top: 25px; }
    .home-phone .menu .display-name {
      height: 40px;
      margin-top: 10px; }
      .home-phone .menu .display-name p {
        font-size: 14px;
        color: white;
        text-align: center;
        padding: 5px 0px;
        margin-bottom: 0px; }
    .home-phone .menu .contact-info p {
      color: white;
      font-size: 13px;
      margin-bottom: 5px;
      text-align: center;
      padding-top: 5px; }
    .home-phone .menu .contact-info h5 {
      color: white;
      font-size: 13px;
      margin-bottom: 5px;
      text-align: center; }
    .home-phone .menu .description {
      height: 18%;
      padding: 7px; }
      .home-phone .menu .description p {
        color: white;
        font-size: 10px;
        word-break: break-all; }
    .home-phone .menu p {
      color: #f36e21;
      font-size: 14px;
      margin-bottom: 8px;
      text-align: center; }
    .home-phone .menu .social-icons {
      display: block;
      text-align: center;
      padding: 5px 10px; }
      .home-phone .menu .social-icons a {
        color: #212529; }
        .home-phone .menu .social-icons a svg {
          margin: 0 18px; }
          .home-phone .menu .social-icons a svg.social {
            background: #f78e1e;
            padding: 5px;
            border-radius: 50%; }
    .home-phone .menu .view-menu-btn {
      padding: 23px 0px; }
      .home-phone .menu .view-menu-btn .btn {
        width: 78%;
        height: 40px;
        background-color: #f78e1e;
        border: none;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: 400;
        justify-content: center;
        display: flex;
        margin: auto;
        letter-spacing: 3px; }
    .home-phone .menu .phone-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px; }
      .home-phone .menu .phone-footer p {
        font-size: 7px;
        color: white;
        margin-bottom: 0px;
        padding-right: 6px;
        letter-spacing: 1px; }
      .home-phone .menu .phone-footer img {
        width: 60px;
        height: 16px; }

.video-screen-container {
  background-color: black;
  width: 100%;
  margin: auto;
  height: 100vh;
  opacity: 0.9;
  max-width: 415px;
  max-height: 823px; }
  .video-screen-container .video {
    width: 100%;
    height: 100%;
    position: relative; }
    .video-screen-container .video .video-screen {
      background: #161617;
      height: 100vh;
      display: flex;
      width: 100%;
      margin: auto; }
      .video-screen-container .video .video-screen .play {
        color: #f36e21;
        margin: auto; }

.image-screen-container {
  background-color: black;
  width: 100%;
  margin: auto;
  height: 100vh;
  opacity: 0.9;
  max-width: 415px;
  max-height: 823px; }
  .image-screen-container .image {
    width: 100%;
    height: 100%;
    position: relative; }
    .image-screen-container .image .image-screen {
      background: #161617;
      height: 100vh;
      display: flex;
      width: 100%;
      margin: auto; }
      .image-screen-container .image .image-screen .image-preview {
        width: 100%;
        height: 100%;
        object-fit: contain; }

.text-screen-container {
  background-color: black;
  width: 100%;
  margin: auto;
  height: 100vh;
  opacity: 0.9;
  max-width: 415px;
  max-height: 823px; }
  .text-screen-container .text {
    width: 100%;
    height: 100%;
    position: relative; }
    .text-screen-container .text .text-screen {
      background: #161617;
      height: 100vh;
      display: flex;
      width: 100%;
      margin: auto; }
      .text-screen-container .text .text-screen .text-content {
        width: 100%;
        margin-top: auto;
        margin-bottom: auto; }
      .text-screen-container .text .text-screen .preview-text {
        text-align: center;
        color: white;
        font-size: 11px;
        margin: 0px 10px;
        word-break: break-all; }
      .text-screen-container .text .text-screen .prev-icon {
        color: #f78e1e;
        display: flex;
        margin: auto;
        margin-bottom: 16px;
        width: 100%; }

.url-screen-container {
  background-color: black;
  width: 100%;
  margin: auto;
  height: 100vh;
  opacity: 0.9;
  max-width: 415px;
  max-height: 823px; }
  .url-screen-container .url {
    width: 100%;
    height: 100%;
    position: relative; }
    .url-screen-container .url .url-screen {
      background: #161617;
      height: 100vh;
      display: flex;
      width: 100%;
      margin: auto; }

.phone-footer {
  color: white;
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 10px; }
  .phone-footer p {
    font-size: 7px;
    font-weight: bold;
    margin-bottom: 0px;
    padding-right: 6px;
    letter-spacing: 1px; }
  .phone-footer img {
    width: 60px;
    height: 16px; }
