.headerBar {
  position: relative;
  width: 100%;
  height: 120px;
  top: 5px;
  padding: 20px 0; }
  .headerBar .container {
    width: 100%;
    max-width: 1516px;
    padding-top: 10px; }
  .headerBar .navbar #logo {
    display: inline-block;
    width: 200px;
    padding: 0; }
    .headerBar .navbar #logo img {
      width: 100%; }
  .headerBar .navbar .navMobile {
    display: none !important; }
  .headerBar .navbar-collapse .navbar-nav {
    align-items: center; }
    .headerBar .navbar-collapse .navbar-nav .nav-link {
      color: #f36e21;
      font-size: 14px;
      background: transparent;
      margin: 0 10px;
      letter-spacing: 4px;
      font-weight: 500;
      padding: 10px 25px; }
      .headerBar .navbar-collapse .navbar-nav .nav-link:hover {
        color: #f36e21; }
    .headerBar .navbar-collapse .navbar-nav .notification {
      margin: 0 10px 0 20px;
      position: relative; }
      .headerBar .navbar-collapse .navbar-nav .notification .bell-icon {
        width: 20px; }
      .headerBar .navbar-collapse .navbar-nav .notification .notification-num {
        left: 12px;
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        top: 10px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        background-color: #f36e21;
        color: #ffffff;
        cursor: pointer; }
    .headerBar .navbar-collapse .navbar-nav .signup-button {
      width: auto;
      background: #f36e21;
      font-size: 19px;
      font-weight: bold;
      border-color: black;
      letter-spacing: 4px;
      color: #fff;
      border-radius: 3px;
      padding: 11px 30px;
      border: 1px solid #000;
      margin-right: 0; }
      .headerBar .navbar-collapse .navbar-nav .signup-button:hover {
        color: #fff; }
    .headerBar .navbar-collapse .navbar-nav .btn-plans {
      width: auto;
      text-transform: uppercase; }
    .headerBar .navbar-collapse .navbar-nav .dropdown a {
      color: #f36e21;
      font-size: 1rem;
      background: transparent;
      border: none;
      letter-spacing: 0;
      height: auto; }
      .headerBar .navbar-collapse .navbar-nav .dropdown a:hover {
        cursor: pointer;
        text-decoration: none; }

.ant-popover {
  width: 280px !important; }

@media (max-width: 980px) and (min-width: 768px) {
  .headerBar {
    height: 140px; }
    .headerBar .navbar {
      flex-flow: column; } }

@media (max-width: 768px) {
  .headerBar {
    padding: 15px 0; }
    .headerBar .navbar {
      padding: 0; }
      .headerBar .navbar .navbar-toggler {
        border: none; }
        .headerBar .navbar .navbar-toggler .navbar-toggler-icon {
          background-image: url("../../assets/icons/toggle-icon.svg"); }
        .headerBar .navbar .navbar-toggler:focus {
          outline: none; }
      .headerBar .navbar .navDesktop {
        display: none !important; }
      .headerBar .navbar .navMobile {
        display: block !important; }
      .headerBar .navbar #logo {
        transform: translateX(-50%);
        position: relative;
        left: 50%; }
    .headerBar .mr-auto {
      display: none; }
    .headerBar .navbar-nav {
      background: #333333 !important;
      text-align: center;
      padding: 25px 15px 30px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold; }
    .headerBar .navbar-collapse {
      transition: 0.5s ease; }
      .headerBar .navbar-collapse .navbar-nav .nav-link {
        font-size: 14px;
        padding: 10px 15px;
        margin: 0;
        letter-spacing: 0px;
        font-weight: 600;
        width: 100%; }
        .headerBar .navbar-collapse .navbar-nav .nav-link:hover {
          opacity: 0.7; }
      .headerBar .navbar-collapse .navbar-nav .signup-button {
        font-size: 19px; } }

@media (max-width: 450px) {
  .headerBar .navbar #logo {
    width: 190px;
    transform: translateX(-60%); } }
