.statistics-container {
  margin-top: 80px; }
  .statistics-container h1 {
    font-size: 47px;
    font-weight: bold;
    margin-bottom: 25px; }
  .statistics-container .value-part {
    display: flex;
    justify-content: space-between; }
    .statistics-container .value-part .sub {
      flex: 20%;
      background-color: #efefef;
      height: 270px;
      margin-right: 23px;
      padding: 40px; }
      .statistics-container .value-part .sub svg {
        display: flex;
        margin: auto;
        color: #f36e21;
        margin-top: 12px; }
      .statistics-container .value-part .sub h1 {
        color: #f36e21;
        text-align: center;
        margin: 16px 0px 12px 0px;
        font-weight: 900; }
      .statistics-container .value-part .sub p {
        text-align: center;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 17px; }
    .statistics-container .value-part .scans {
      flex: 40%;
      background-color: #efefef;
      height: 270px;
      display: flex; }
      .statistics-container .value-part .scans .phones {
        flex: 40%;
        padding: 60px 20px; }
        .statistics-container .value-part .scans .phones p {
          font-size: 18px;
          font-weight: 500; }
      .statistics-container .value-part .scans .diagram {
        flex: 60%;
        padding: 60px 20px; }
        .statistics-container .value-part .scans .diagram .iphone-value {
          display: flex; }
          .statistics-container .value-part .scans .diagram .iphone-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .statistics-container .value-part .scans .diagram .iphone-value .bar {
            background-color: #f36e21;
            width: 150px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px;
            align-items: center; }
        .statistics-container .value-part .scans .diagram .android-value {
          display: flex; }
          .statistics-container .value-part .scans .diagram .android-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .statistics-container .value-part .scans .diagram .android-value .bar {
            background-color: #f36e21;
            width: 120px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px;
            align-items: center; }
        .statistics-container .value-part .scans .diagram .black-value {
          display: flex; }
          .statistics-container .value-part .scans .diagram .black-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .statistics-container .value-part .scans .diagram .black-value .bar {
            background-color: #f36e21;
            width: 50px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px 5px 20px;
            align-items: center; }
        .statistics-container .value-part .scans .diagram .winphone-value {
          display: flex; }
          .statistics-container .value-part .scans .diagram .winphone-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .statistics-container .value-part .scans .diagram .winphone-value .bar {
            background-color: #f36e21;
            width: 20px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px 5px 28px;
            align-items: center; }
  .statistics-container .filter {
    display: flex;
    justify-content: space-between;
    padding: 50px 0px; }
    .statistics-container .filter .during {
      flex: 20%;
      height: 50px;
      margin-right: 23px;
      display: flex;
      align-items: center;
      font-size: 12px; }
      .statistics-container .filter .during .during-item {
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid;
        text-align: center;
        background-color: #efefef;
        padding: 14px;
        cursor: pointer; }
        .statistics-container .filter .during .during-item:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-right: none; }
        .statistics-container .filter .during .during-item:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-left: none; }
        .statistics-container .filter .during .during-item.active {
          background-color: #f36e21; }
      .statistics-container .filter .during .btn {
        width: 33.3%; }
    .statistics-container .filter .firstdate {
      flex: 20%;
      margin-right: 23px;
      width: 100%;
      position: relative;
      display: flex; }
      .statistics-container .filter .firstdate .react-datepicker-wrapper {
        width: 100%; }
        .statistics-container .filter .firstdate .react-datepicker-wrapper .react-datepicker__input-container input {
          width: 100%;
          height: 49px;
          border-radius: 9px;
          border-color: #414042;
          border-width: 1px;
          font-size: 13px;
          padding-left: 20px;
          outline: none; }
      .statistics-container .filter .firstdate .cal {
        position: absolute;
        width: 25px;
        height: 25px;
        color: #f36e21;
        right: 10px;
        margin-top: 10px; }
    .statistics-container .filter .enddate {
      flex: 20%;
      margin-right: 23px;
      width: 100%;
      position: relative;
      display: flex; }
      .statistics-container .filter .enddate .react-datepicker-wrapper {
        width: 100%; }
        .statistics-container .filter .enddate .react-datepicker-wrapper .react-datepicker__input-container input {
          width: 100%;
          height: 49px;
          border-radius: 9px;
          border-color: #414042;
          border-width: 1px;
          font-size: 13px;
          padding-left: 20px;
          outline: none; }
      .statistics-container .filter .enddate .cal {
        position: absolute;
        width: 25px;
        height: 25px;
        color: #f36e21;
        right: 10px;
        margin-top: 10px; }
    .statistics-container .filter .search {
      flex: 40%;
      width: 100%;
      position: relative; }
      .statistics-container .filter .search .search-input-wrapper {
        width: fit-content;
        margin-left: auto;
        position: relative; }
        .statistics-container .filter .search .search-input-wrapper .search-input {
          width: 350px;
          height: 49px;
          border-radius: 9px;
          border-width: 1px;
          border-color: #414042;
          padding-left: 40px;
          outline: none; }
        .statistics-container .filter .search .search-input-wrapper .search-icon {
          position: absolute;
          width: 25px;
          height: 25px;
          color: #f36e21;
          left: 10px;
          top: 10px; }
  .statistics-container .chart-part {
    margin-bottom: 80px; }

.canvasjs-chart-credit {
  display: none; }

.mob-statistics-container {
  margin-top: 3rem; }
  .mob-statistics-container h1 {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-align: center; }
  .mob-statistics-container .value-part {
    display: flex;
    justify-content: space-between; }
    .mob-statistics-container .value-part .col-4 {
      padding: 0 0.1rem; }
    .mob-statistics-container .value-part .sub {
      background-color: #efefef;
      height: 7rem;
      padding: 0.5rem; }
      .mob-statistics-container .value-part .sub svg {
        display: flex;
        margin: auto;
        color: #f36e21;
        margin-top: 0.5rem;
        width: 100%; }
      .mob-statistics-container .value-part .sub h1 {
        color: #f36e21;
        text-align: center;
        margin: 0.5rem 0;
        font-weight: 900; }
      .mob-statistics-container .value-part .sub p {
        text-align: center;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 0.4rem;
        margin-bottom: 0; }
    .mob-statistics-container .value-part .scans {
      flex: 40%;
      background-color: #efefef;
      height: 270px;
      display: flex; }
      .mob-statistics-container .value-part .scans .phones {
        flex: 40%;
        padding: 60px 20px; }
        .mob-statistics-container .value-part .scans .phones p {
          font-size: 18px;
          font-weight: 500; }
      .mob-statistics-container .value-part .scans .diagram {
        flex: 60%;
        padding: 60px 20px; }
        .mob-statistics-container .value-part .scans .diagram .iphone-value {
          display: flex; }
          .mob-statistics-container .value-part .scans .diagram .iphone-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .mob-statistics-container .value-part .scans .diagram .iphone-value .bar {
            background-color: #f36e21;
            width: 150px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px;
            align-items: center; }
        .mob-statistics-container .value-part .scans .diagram .android-value {
          display: flex; }
          .mob-statistics-container .value-part .scans .diagram .android-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .mob-statistics-container .value-part .scans .diagram .android-value .bar {
            background-color: #f36e21;
            width: 120px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px;
            align-items: center; }
        .mob-statistics-container .value-part .scans .diagram .black-value {
          display: flex; }
          .mob-statistics-container .value-part .scans .diagram .black-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .mob-statistics-container .value-part .scans .diagram .black-value .bar {
            background-color: #f36e21;
            width: 50px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px 5px 20px;
            align-items: center; }
        .mob-statistics-container .value-part .scans .diagram .winphone-value {
          display: flex; }
          .mob-statistics-container .value-part .scans .diagram .winphone-value p {
            font-size: 18px;
            font-weight: bold;
            color: #f36e21; }
          .mob-statistics-container .value-part .scans .diagram .winphone-value .bar {
            background-color: #f36e21;
            width: 20px;
            height: 15px;
            border-radius: 30px;
            margin: 5px 15px 5px 28px;
            align-items: center; }
  .mob-statistics-container .filter {
    display: flex;
    justify-content: space-between;
    padding: 50px 0px; }
    .mob-statistics-container .filter .during {
      display: flex;
      align-items: center;
      font-size: 12px; }
      .mob-statistics-container .filter .during .during-item {
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid;
        text-align: center;
        background-color: #efefef;
        padding: 14px;
        cursor: pointer;
        font-size: 0.5rem; }
        .mob-statistics-container .filter .during .during-item:first-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          border-right: none; }
        .mob-statistics-container .filter .during .during-item:last-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-left: none; }
        .mob-statistics-container .filter .during .during-item.active {
          background-color: #f36e21; }
      .mob-statistics-container .filter .during .btn {
        width: 33.3%; }
    .mob-statistics-container .filter .firstdate {
      margin: 1rem 0;
      width: 100%;
      position: relative;
      display: flex; }
      .mob-statistics-container .filter .firstdate .react-datepicker-wrapper {
        width: 100%; }
        .mob-statistics-container .filter .firstdate .react-datepicker-wrapper .react-datepicker__input-container input {
          width: 100%;
          height: 2.5rem;
          border-radius: 0.25rem;
          border-color: #414042;
          border-width: 1px;
          font-size: 0.5rem;
          padding-left: 20px;
          outline: none; }
      .mob-statistics-container .filter .firstdate .cal {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        color: #f36e21;
        right: 1.5rem;
        margin-top: 0.5rem; }
    .mob-statistics-container .filter .enddate {
      width: 100%;
      position: relative;
      display: flex; }
      .mob-statistics-container .filter .enddate .react-datepicker-wrapper {
        width: 100%; }
        .mob-statistics-container .filter .enddate .react-datepicker-wrapper .react-datepicker__input-container input {
          width: 100%;
          height: 2.5rem;
          border-radius: 0.25rem;
          border-color: #414042;
          border-width: 1px;
          font-size: 0.5rem;
          padding-left: 20px;
          outline: none; }
      .mob-statistics-container .filter .enddate .cal {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        color: #f36e21;
        right: 1.5rem;
        margin-top: 0.5rem; }
  .mob-statistics-container .chart-part {
    margin-bottom: 2rem;
    font-size: 0.7rem; }
