.my-hipscans .hipscan .action-part .action .new-create-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.my-hipscans .mob-hipscan .action-part .action .new-create-btn .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.my-hipscans .hipscan {
  position: relative;
  height: 760px;
  width: 100%;
  display: flex; }
  .my-hipscans .hipscan .action-part {
    flex: 66%;
    height: 100%; }
    .my-hipscans .hipscan .action-part .action-image {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.03;
      z-index: -1;
      object-fit: cover; }
    .my-hipscans .hipscan .action-part .action {
      padding: 18px 80px;
      display: flex;
      justify-content: space-between; }
      .my-hipscans .hipscan .action-part .action .title {
        flex: 70%;
        padding-right: 200px; }
        .my-hipscans .hipscan .action-part .action .title h1 {
          font-weight: bold;
          letter-spacing: 1px;
          margin: 21px 0px;
          color: #414042; }
      .my-hipscans .hipscan .action-part .action .new-create-btn {
        flex: 30%; }
    .my-hipscans .hipscan .action-part .qr-s {
      padding: 18px 80px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 446px; }
      .my-hipscans .hipscan .action-part .qr-s .qr-first {
        flex: 0 0 30%;
        height: 310px;
        padding: 25px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-first img {
          margin-bottom: 10px;
          width: 100%;
          height: 70%;
          object-fit: contain;
          cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-first .active {
          padding: 7px;
          border: 3px solid orange;
          border-radius: 10px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-first .label {
          width: 100%;
          height: 40px;
          text-align: center;
          display: flex;
          position: relative; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .label .edit {
            position: absolute;
            right: 6px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-first .label .edit .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .label .save-cancel {
            position: absolute;
            right: -20px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-first .label .save-cancel .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .label p {
            font-size: 15px;
            text-align: center;
            margin-bottom: 0rem;
            margin: auto; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .label input {
            width: 70%;
            border-radius: 5px;
            margin: auto; }
        .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit-delete {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit-delete .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit-delete .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-first .eye-edit-delete .delete {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
      .my-hipscans .hipscan .action-part .qr-s .qr-second {
        flex: 0 0 30%;
        height: 310px;
        padding: 25px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-second img {
          margin-bottom: 10px;
          width: 100%;
          height: 70%;
          object-fit: contain;
          cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-second .active {
          padding: 7px;
          border: 3px solid orange;
          border-radius: 10px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-second .label {
          width: 100%;
          height: 40px;
          text-align: center;
          display: flex;
          position: relative; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .label .edit {
            position: absolute;
            right: 6px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-second .label .edit .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .label .save-cancel {
            position: absolute;
            right: -20px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-second .label .save-cancel .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .label p {
            font-size: 15px;
            text-align: center;
            margin-bottom: 0rem;
            margin: auto; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .label input {
            width: 70%;
            border-radius: 5px;
            margin: auto; }
        .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit-delete {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit-delete .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit-delete .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-second .eye-edit-delete .delete {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
      .my-hipscans .hipscan .action-part .qr-s .qr-third {
        flex: 0 0 30%;
        height: 310px;
        padding: 25px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-third img {
          margin-bottom: 10px;
          width: 100%;
          height: 70%;
          object-fit: contain;
          cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-third .active {
          padding: 7px;
          border: 3px solid orange;
          border-radius: 10px; }
        .my-hipscans .hipscan .action-part .qr-s .qr-third .label {
          width: 100%;
          height: 40px;
          text-align: center;
          display: flex;
          position: relative; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .label .edit {
            position: absolute;
            right: 6px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-third .label .edit .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .label .save-cancel {
            position: absolute;
            right: -20px;
            top: 6px; }
            .my-hipscans .hipscan .action-part .qr-s .qr-third .label .save-cancel .icon {
              color: #f78e1e;
              cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .label p {
            font-size: 15px;
            text-align: center;
            margin-bottom: 0rem;
            margin: auto; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .label input {
            width: 70%;
            border-radius: 5px;
            margin: auto; }
        .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
        .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit-delete {
          display: flex;
          padding: 0px 20px; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit-delete .eye {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit-delete .edit {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
          .my-hipscans .hipscan .action-part .qr-s .qr-third .eye-edit-delete .delete {
            display: flex;
            margin: auto;
            color: #f78e1e;
            cursor: pointer; }
  .my-hipscans .hipscan .preview-part {
    flex: 34%;
    height: 100%;
    background-color: #efefef;
    position: relative; }
    .my-hipscans .hipscan .preview-part .title {
      text-align: center;
      margin-top: 35px;
      font-size: 19px;
      font-weight: 500;
      color: #414042; }
    .my-hipscans .hipscan .preview-part .phone-frame .phone {
      width: 260px;
      position: absolute;
      left: 50%;
      margin-left: -130px;
      z-index: -1; }
    .my-hipscans .hipscan .preview-part .phone-frame .phone-screen {
      background-color: #000000cc;
      width: 360px;
      margin: auto;
      height: 640px;
      border-radius: 40px;
      border: 11px solid #080808;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow: hidden; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen::-webkit-scrollbar {
        display: none; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen video {
        border-radius: 40px; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen .background {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 28px;
        opacity: 0.1;
        z-index: 22;
        pointer-events: none; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen .prev-icon {
        margin-bottom: 16px;
        color: #f78e1e;
        display: flex;
        margin: auto;
        margin-top: 190px;
        margin-bottom: 16px;
        width: 100%; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen h5 {
        color: white;
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        margin-top: 15px; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen iframe {
        border-radius: 28px; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen .preview-text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen .preview-text {
        text-align: center;
        color: white;
        font-size: 11px;
        margin: 0px 10px;
        word-break: break-all; }
      .my-hipscans .hipscan .preview-part .phone-frame .phone-screen .image-preview {
        width: calc(100% + 1px);
        height: 100%; }

.my-hipscans .statistics {
  margin: 0 7%; }

.my-hipscans .mob-hipscan {
  position: relative;
  width: 100%;
  display: flex; }
  .my-hipscans .mob-hipscan .action-part .action-image {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.03;
    z-index: -1;
    object-fit: cover; }
  .my-hipscans .mob-hipscan .action-part .action {
    padding: 1rem;
    text-align: center; }
    .my-hipscans .mob-hipscan .action-part .action .title h1 {
      font-weight: bold;
      letter-spacing: 1px;
      margin: 21px 0px;
      color: #414042;
      font-size: 1.3rem;
      text-align: center; }
    .my-hipscans .mob-hipscan .action-part .action .title p {
      font-size: 0.7rem;
      text-align: center; }
  .my-hipscans .mob-hipscan .action-part .qr-s {
    padding: 1rem;
    display: block;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 20rem; }
    .my-hipscans .mob-hipscan .action-part .qr-s .qr {
      flex: 0 0 30%;
      height: 310px;
      padding: 0 25px; }
      .my-hipscans .mob-hipscan .action-part .qr-s .qr img {
        margin-bottom: 10px;
        width: 100%;
        height: 70%;
        object-fit: contain;
        cursor: pointer; }
      .my-hipscans .mob-hipscan .action-part .qr-s .qr .active {
        padding: 7px;
        border: 3px solid orange;
        border-radius: 10px; }
      .my-hipscans .mob-hipscan .action-part .qr-s .qr .label {
        width: 100%;
        height: 40px;
        text-align: center;
        display: flex;
        position: relative; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .label .edit {
          position: absolute;
          right: 6px;
          top: 6px; }
          .my-hipscans .mob-hipscan .action-part .qr-s .qr .label .edit .icon {
            color: #f78e1e;
            cursor: pointer; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .label .save-cancel {
          position: absolute;
          right: -20px;
          top: 6px; }
          .my-hipscans .mob-hipscan .action-part .qr-s .qr .label .save-cancel .icon {
            color: #f78e1e;
            cursor: pointer; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .label p {
          font-size: 0.8rem;
          text-align: center;
          margin-bottom: 0rem;
          margin: auto; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .label input {
          width: 70%;
          border-radius: 5px;
          margin: auto; }
      .my-hipscans .mob-hipscan .action-part .qr-s .qr .eye-edit-delete {
        display: flex;
        padding: 0px 20px; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .eye-edit-delete .edit {
          display: flex;
          margin: auto;
          color: #f78e1e;
          cursor: pointer; }
        .my-hipscans .mob-hipscan .action-part .qr-s .qr .eye-edit-delete .delete {
          display: flex;
          margin: auto;
          color: #f78e1e;
          cursor: pointer; }
