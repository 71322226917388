.launch-url .hipscan .action-part .action .save-continue-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.launch-url .hipscan {
  position: relative;
  height: 760px;
  width: 100%;
  display: flex; }
  .launch-url .hipscan .action-part {
    flex: 66%;
    height: 100%; }
    .launch-url .hipscan .action-part .action-image {
      width: 66%;
      height: 100%;
      position: absolute;
      opacity: 0.03;
      z-index: -1;
      object-fit: cover; }
    .launch-url .hipscan .action-part .action {
      padding: 18px 80px; }
      .launch-url .hipscan .action-part .action .goto {
        text-decoration: none;
        color: #f36e21; }
        .launch-url .hipscan .action-part .action .goto svg {
          margin-bottom: 3px; }
      .launch-url .hipscan .action-part .action h1 {
        font-weight: bold;
        letter-spacing: 1px;
        margin: 21px 0px;
        color: #414042; }
      .launch-url .hipscan .action-part .action input {
        height: 57px;
        width: 560px;
        border-radius: 10px;
        border-color: #414042;
        display: block;
        padding-left: 22px;
        outline: none; }
  .launch-url .hipscan .preview-part {
    flex: 34%;
    height: 100%;
    background-color: #efefef;
    position: relative; }
    .launch-url .hipscan .preview-part .title {
      text-align: center;
      margin-top: 35px;
      font-size: 19px;
      font-weight: 500;
      color: #414042; }
    .launch-url .hipscan .preview-part .phone-frame {
      text-align: center; }
      .launch-url .hipscan .preview-part .phone-frame .phone {
        width: 260px;
        position: absolute;
        left: 50%;
        margin-left: -130px; }
      .launch-url .hipscan .preview-part .phone-frame .phone-screen {
        background-color: #000000cc;
        width: 360px;
        margin: auto;
        height: 640px;
        border-radius: 55px;
        padding: 8px;
        position: relative;
        z-index: 999999999; }
        .launch-url .hipscan .preview-part .phone-frame .phone-screen p {
          color: white;
          margin-top: 140px; }
        .launch-url .hipscan .preview-part .phone-frame .phone-screen .icon {
          margin-top: 160px;
          margin-bottom: 16px;
          color: #f78e1e; }
        .launch-url .hipscan .preview-part .phone-frame .phone-screen h5 {
          color: white;
          font-size: 15px;
          font-weight: 300; }
        .launch-url .hipscan .preview-part .phone-frame .phone-screen iframe {
          border-radius: 33px; }

.launch-url .statistics {
  margin: 0 7%; }
