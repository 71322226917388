.menu-modal-container .modal-content .modal-body .save-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.menu-modal-container .modal-content .modal-body .add-btn {
  width: 100%; }
  .menu-modal-container .modal-content .modal-body .add-btn .btn {
    width: 100%;
    height: 57px;
    background-color: white;
    border-radius: 9px;
    border: 2px dashed;
    font-size: 16px;
    color: #f78e1e;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 12px 0px;
    text-align: left;
    padding-left: 30px;
    box-shadow: none; }

.menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name, .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price, .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name, .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price {
  position: relative;
  display: flex;
  width: 100%; }
  .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name input, .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price input, .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name input, .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }

.menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload {
  position: relative;
  display: flex;
  width: 100%; }
  .menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }
  .menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload .icon {
    position: absolute;
    right: 0px;
    background-color: #f78e1e;
    color: white;
    height: 57px;
    width: 60px;
    padding: 9px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #414042;
    cursor: pointer; }
    .menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload .icon.left {
      right: 60px;
      border-radius: 0;
      border-right: 0; }
  .menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload span {
    border: 1px solid;
    width: 100%;
    height: 57px;
    border-radius: 10px;
    align-items: center;
    padding-left: 20px;
    color: #414042;
    display: flex;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important; }

.menu-modal-container .modal-content .modal-body .edit-product .input-field .description {
  width: 100%;
  height: 120px; }
  .menu-modal-container .modal-content .modal-body .edit-product .input-field .description textarea {
    width: 100%;
    height: 100%;
    border-color: #414042;
    border-radius: 10px;
    padding: 10px 22px;
    outline: none; }

.mob-menu-modal-container .modal-content .modal-body .save-btn .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-menu-modal-container .modal-content .modal-body .add-btn {
  width: 100%; }
  .mob-menu-modal-container .modal-content .modal-body .add-btn .btn {
    width: 100%;
    background-color: white;
    border-radius: 0.25rem;
    border: 2px dashed;
    font-size: 0.7rem;
    color: #f78e1e;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 0;
    text-align: left;
    height: 2.4rem;
    box-shadow: none; }

.mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name, .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price, .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name, .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name input, .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price input, .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name input, .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    border: 1px solid #414042;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload .icon {
    position: absolute;
    right: 0px;
    background-color: #f78e1e;
    color: white;
    padding: 0.3rem;
    text-align: center;
    border: 1px solid #414042;
    cursor: pointer;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    height: 2.3rem;
    width: 2.3rem; }
    .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload .icon.left {
      right: 2.3rem;
      border-radius: 0;
      border-right: 0; }
  .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload span {
    border: 1px solid;
    width: 100%;
    border-radius: 0.25rem;
    align-items: center;
    color: #414042;
    display: flex;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-size: 0.8rem;
    height: auto;
    padding: 0.5rem 0.8rem; }

.mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .description {
  width: 100%;
  height: 5rem;
  margin: 0.5rem 0;
  margin-bottom: 2rem; }
  .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .description textarea {
    width: 100%;
    height: 100%;
    border-color: #414042;
    border-radius: 0.25rem;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.menu-modal-container {
  max-width: 62% !important;
  margin-top: 116px !important; }
  .menu-modal-container .modal-content .modal-header {
    padding: 60px 60px 20px; }
    .menu-modal-container .modal-content .modal-header .modal-title {
      font-size: 38px;
      font-weight: 700; }
  .menu-modal-container .modal-content .modal-body {
    padding: 20px 60px 40px;
    border: #f36e21; }
    .menu-modal-container .modal-content .modal-body .edit-product {
      display: flex;
      width: 100%;
      margin-bottom: 20px; }
      .menu-modal-container .modal-content .modal-body .edit-product .input-field {
        width: 75%; }
        .menu-modal-container .modal-content .modal-body .edit-product .input-field .image-upload {
          padding: 15px 0px; }
        .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price {
          padding: 12px 0px;
          display: flex;
          justify-content: space-between; }
          .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name {
            width: 68%; }
          .menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price {
            width: 28%; }
        .menu-modal-container .modal-content .modal-body .edit-product .input-field .description {
          padding: 12px 0px;
          display: flex;
          justify-content: space-between; }
      .menu-modal-container .modal-content .modal-body .edit-product .image-preview {
        width: 25%;
        padding: 40px 20px 40px 40px; }
        .menu-modal-container .modal-content .modal-body .edit-product .image-preview .image {
          width: 100%;
          height: 150px;
          background: #efefef;
          display: flex;
          align-items: center; }
          .menu-modal-container .modal-content .modal-body .edit-product .image-preview .image ._image {
            width: 100%;
            height: 100%;
            object-fit: contain; }
          .menu-modal-container .modal-content .modal-body .edit-product .image-preview .image p {
            text-align: center; }
    .menu-modal-container .modal-content .modal-body .modifiers {
      display: flex;
      width: 100%;
      border-top: solid 1px;
      border-color: #f36e21; }
      .menu-modal-container .modal-content .modal-body .modifiers .input-field {
        width: 75%; }
        .menu-modal-container .modal-content .modal-body .modifiers .input-field p {
          color: #f36e21;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 700; }
        .menu-modal-container .modal-content .modal-body .modifiers .input-field .first {
          position: relative;
          padding: 6px 0px;
          display: flex;
          justify-content: space-between; }
          .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name {
            width: 68%; }
          .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price {
            width: 28%; }
          .menu-modal-container .modal-content .modal-body .modifiers .input-field .first .cancel {
            position: absolute;
            right: -40px;
            top: 25px; }
    .menu-modal-container .modal-content .modal-body .save-btn {
      display: flex;
      align-items: center; }
      .menu-modal-container .modal-content .modal-body .save-btn p {
        color: #f36e21;
        flex: 1;
        cursor: pointer; }

.mob-menu-modal-container .modal-content .modal-header {
  justify-content: center;
  align-items: center; }
  .mob-menu-modal-container .modal-content .modal-header .modal-title {
    font-size: 1.3rem;
    font-weight: 700; }

.mob-menu-modal-container .modal-content .modal-body {
  padding: 1rem;
  border: #f36e21; }
  .mob-menu-modal-container .modal-content .modal-body .edit-product {
    display: flex;
    width: 100%; }
    .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field {
      width: 100%; }
      .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price {
        display: flex;
        justify-content: space-between; }
        .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .name {
          width: 68%; }
        .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .name-price .price {
          width: 28%; }
      .mob-menu-modal-container .modal-content .modal-body .edit-product .input-field .description {
        display: flex;
        justify-content: space-between; }
  .mob-menu-modal-container .modal-content .modal-body .modifiers {
    display: flex;
    width: 100%;
    border-top: solid 1px;
    border-color: #f36e21;
    margin-bottom: 2rem; }
    .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field {
      width: 100%; }
      .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field p {
        color: #f36e21;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700; }
      .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first {
        position: relative;
        padding: 6px 0px;
        display: flex;
        justify-content: space-between; }
        .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .name {
          width: 68%; }
        .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .price {
          width: 28%; }
        .mob-menu-modal-container .modal-content .modal-body .modifiers .input-field .first .cancel {
          position: absolute;
          right: -40px;
          top: 25px; }
  .mob-menu-modal-container .modal-content .modal-body .save-btn {
    text-align: center; }
    .mob-menu-modal-container .modal-content .modal-body .save-btn p {
      color: #f36e21;
      flex: 1;
      cursor: pointer; }
