.launch-event-modal .launch-event-modal-body .input-field {
  justify-content: space-between;
  display: flex; }

.launch-event-modal .launch-event-modal-body {
  text-align: center;
  padding: 0 70px 40px 70px; }

.launch-event-modal .launch-event-modal-body .input-field .common-input {
  width: 100%; }

.launch-event-modal .launch-event-modal-body .input-field .common-input input {
  height: 57px;
  width: 80%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none; }

.launch-event-modal .launch-event-modal-body .save-continue-btn {
  display: block; }

.launch-event-modal .launch-event-modal-body .save-continue-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 16px 0px;
  box-shadow: none; }

.launch-event-modal .modal-header {
  border-bottom: 0px; }

.partner-hipscan-index {
  text-align: left;
  color: #ff8400;
  font-size: xx-large; }

.launch-event-modal .modal-dialog {
  max-width: 750px !important;
  margin: 1.75rem auto; }

.partners-input {
  text-align: center; }

.modal-title {
  font-weight: bold;
  line-height: 1.3 !important; }

.input-field1 .common-input {
  position: relative;
  display: flex;
  width: 100%; }

.input-field1 .common-input input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none;
  margin-inline: 0 10px; }

.input-field1 .file-upload {
  position: relative;
  display: flex;
  width: 100%; }

.input-field1 .file-upload input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none; }

.input-field1 .file-upload .icon {
  position: absolute;
  right: 0px;
  background-color: #f78e1e;
  color: white;
  height: 57px;
  width: 60px;
  padding: 9px;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #414042;
  cursor: pointer; }

.input-field1 .file-upload .icon.left {
  right: 60px;
  border-radius: 0;
  border-right: 0; }

.input-field1 .file-upload span {
  border: 1px solid;
  width: 100%;
  height: 57px;
  border-radius: 10px;
  align-items: center;
  padding-left: 20px;
  color: #414042;
  display: flex;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body .input-field {
  justify-content: space-between;
  display: flex; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body {
  padding: 0 70px 40px 70px; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body .input-field .common-input {
  width: 100%; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body .input-field .common-input input {
  height: 57px;
  width: 80%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body .save-continue-btn {
  display: block; }

.launch-event-hipscan-modal .launch-event-hipscan-modal-body .save-continue-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 4px 0px;
  box-shadow: none; }

.launch-event-hipscan-modal .modal-header {
  border-bottom: 0px; }

.launch-event-hipscan-modal .modal-dialog {
  max-width: 1200px !important;
  margin: 1.75rem auto; }

.hipscan-input {
  width: 100%;
  margin-block: auto;
  margin-bottom: 24px; }
