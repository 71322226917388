.menu-phone {
  height: 100%; }
  .menu-phone .menu {
    width: 100%;
    height: 100%;
    padding: 12px;
    position: relative; }
    .menu-phone .menu .product-details {
      margin: -12px;
      margin-top: 100px;
      height: calc(100% - 119px); }
      .menu-phone .menu .product-details .add-to-cart {
        position: absolute;
        width: 100%;
        background: white;
        bottom: 0;
        padding: 10px 20px;
        border-top: solid 2px #ccc; }
        .menu-phone .menu .product-details .add-to-cart .cart-button {
          background: #f36e21;
          color: white;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          border-radius: 4px;
          cursor: pointer; }
      .menu-phone .menu .product-details .detail-container {
        background-color: #eee;
        height: 100%; }
        .menu-phone .menu .product-details .detail-container .header {
          padding: 20px; }
          .menu-phone .menu .product-details .detail-container .header p {
            text-align: left; }
            .menu-phone .menu .product-details .detail-container .header p:first-child {
              font-size: 16px;
              font-weight: bold; }
            .menu-phone .menu .product-details .detail-container .header p:last-child {
              color: black; }
        .menu-phone .menu .product-details .detail-container .mod-list .mod {
          background: white;
          border-bottom: solid 2px #ccc;
          padding: 20px; }
          .menu-phone .menu .product-details .detail-container .mod-list .mod:first-child {
            font-size: 16px;
            font-weight: bold; }
          .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* When the checkbox is checked, add a blue background */
            /* Create the checkmark/indicator (hidden when not checked) */
            /* Show the checkmark when checked */
            /* Style the checkmark/indicator */ }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) label {
              min-width: 20%; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container {
              display: block;
              position: relative;
              padding-left: 35px;
              cursor: pointer;
              font-size: 16px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none; }
              .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container:hover input ~ .checkmark {
              background-color: #ccc; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input:checked ~ .checkmark {
              background-color: #f36e21; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .checkmark:after {
              content: "";
              position: absolute;
              display: none; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container input:checked ~ .checkmark:after {
              display: block; }
            .menu-phone .menu .product-details .detail-container .mod-list .mod:not(:first-child) .container .checkmark:after {
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
    .menu-phone .menu .go-back {
      color: white;
      font-size: 40px;
      line-height: 0.5;
      cursor: pointer; }
    .menu-phone .menu .logo-container {
      display: flex; }
      .menu-phone .menu .logo-container .menu-photo img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #f36e21;
        justify-content: center;
        margin: auto;
        margin-left: 20px; }
      .menu-phone .menu .logo-container .menu-info {
        justify-content: center;
        align-items: center;
        padding-left: 20px; }
        .menu-phone .menu .logo-container .menu-info p {
          color: white; }
          .menu-phone .menu .logo-container .menu-info p.menu-title {
            margin-top: 25px;
            font-weight: bold; }
    .menu-phone .menu .categories-container {
      background: white;
      margin: 0 -12px;
      border: solid 1px #ccc;
      margin-top: 20px; }
      .menu-phone .menu .categories-container .category-item .category-title {
        padding: 20px;
        border-bottom: solid 1px #ccc; }
        .menu-phone .menu .categories-container .category-item .category-title span {
          font-weight: bold; }
      .menu-phone .menu .categories-container .category-item .product-list .product-item {
        padding: 20px;
        display: flex;
        border-bottom: solid 1px #ccc; }
        .menu-phone .menu .categories-container .category-item .product-list .product-item .product-image img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          cursor: pointer;
          object-fit: cover; }
        .menu-phone .menu .categories-container .category-item .product-list .product-item .product-info {
          padding-left: 20px; }
          .menu-phone .menu .categories-container .category-item .product-list .product-item .product-info p {
            text-align: left;
            color: black; }
            .menu-phone .menu .categories-container .category-item .product-list .product-item .product-info p.product-name {
              font-weight: bold;
              cursor: pointer; }
            .menu-phone .menu .categories-container .category-item .product-list .product-item .product-info p.product-desc {
              font-size: 12px; }
      .menu-phone .menu .categories-container .category-item .category-separator {
        height: 20px;
        background: #eee; }
    .menu-phone .menu .background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: -12px;
      border-radius: 32px;
      opacity: 0.1;
      z-index: -1; }
    .menu-phone .menu .menu-photo {
      height: 30%;
      display: flex; }
      .menu-phone .menu .menu-photo img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #f36e21;
        justify-content: center;
        margin: auto;
        margin-top: 30px; }
    .menu-phone .menu .display-name {
      height: 33px; }
      .menu-phone .menu .display-name p {
        font-size: 14px;
        color: white;
        text-align: center;
        padding: 5px 0px;
        margin-bottom: 0px; }
    .menu-phone .menu .contact-info p {
      color: #f78e1e;
      font-size: 11px;
      margin-bottom: 5px;
      text-align: center;
      padding-top: 5px; }
    .menu-phone .menu .contact-info h5 {
      color: #f78e1e;
      font-size: 11px;
      margin-bottom: 5px;
      text-align: center; }
    .menu-phone .menu .description {
      height: 18%;
      padding: 7px; }
      .menu-phone .menu .description p {
        color: white;
        font-size: 9px;
        word-break: break-all; }
    .menu-phone .menu p {
      color: #f36e21;
      font-size: 13px;
      margin-bottom: 8px;
      text-align: center; }
    .menu-phone .menu .social-icons {
      display: block;
      text-align: center;
      padding: 5px 10px; }
      .menu-phone .menu .social-icons a {
        color: #212529; }
        .menu-phone .menu .social-icons a svg {
          margin: 0 18px;
          background: #f78e1e;
          padding: 5px;
          border-radius: 50%; }
    .menu-phone .menu .view-menu-btn {
      padding: 27px 0px; }
      .menu-phone .menu .view-menu-btn .btn {
        width: 200px;
        height: 30px;
        background-color: #f78e1e;
        border: none;
        border-radius: 5px;
        font-size: 9px;
        letter-spacing: 1px;
        font-weight: 400;
        justify-content: center;
        display: flex;
        margin: auto;
        letter-spacing: 3px; }
    .menu-phone .menu .phone-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      position: absolute;
      bottom: 0;
      width: calc(100% - 24px); }
      .menu-phone .menu .phone-footer p {
        font-size: 7px;
        font-weight: bold;
        color: white;
        margin-bottom: 0px;
        padding-right: 6px;
        letter-spacing: 1px; }
      .menu-phone .menu .phone-footer img {
        width: 60px;
        height: 16px; }
