.product-phone-preview {
  width: 100%;
  height: 100%;
  position: relative; }
  .product-phone-preview .product-photo {
    height: 30%;
    display: flex;
    margin: 0px 2px; }
    .product-phone-preview .product-photo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      justify-content: center;
      margin: auto;
      border-top-left-radius: 33px;
      border-top-right-radius: 33px; }
  .product-phone-preview .product-name {
    height: 33px;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0px 3px; }
    .product-phone-preview .product-name p {
      font-size: 12px;
      color: #f36e21;
      font-weight: bold;
      text-align: center;
      padding: 5px 0px 0px 0px;
      margin-bottom: 0px; }
  .product-phone-preview .price {
    height: 40px;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 10px;
    margin: 0px 3px; }
    .product-phone-preview .price h1 {
      font-size: 28px;
      font-weight: 900;
      letter-spacing: 2px;
      margin-bottom: 0px; }
  .product-phone-preview .description {
    padding: 14px;
    background-color: #efefef;
    margin: 0px 3px;
    overflow-y: auto; }
    .product-phone-preview .description p {
      color: black;
      font-size: 10px;
      word-break: break-all; }
  .product-phone-preview .description.short {
    height: 22%; }
  .product-phone-preview .description.long {
    height: 50%; }
  .product-phone-preview .video-screen {
    background: #161617;
    height: 23%;
    display: flex;
    margin: 12px; }
    .product-phone-preview .video-screen .play {
      color: #f36e21;
      margin: auto; }
  .product-phone-preview .phone-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    position: absolute;
    bottom: 10px;
    width: calc(100% - 24px); }
    .product-phone-preview .phone-footer p {
      font-size: 7px;
      font-weight: bold;
      color: white;
      margin-bottom: 0px;
      padding-right: 6px;
      letter-spacing: 1px; }
    .product-phone-preview .phone-footer img {
      width: 60px;
      height: 16px; }
