.detail-my-hipscan .hipscan .action-part .action-edit-url .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-text .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-video .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-image .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-profile .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-flyer .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-product .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-menu .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-actions .save-continue-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-actions .cancel-btn .btn, .mob-hipscan .action-edit-design .custom-design-page .design-options .design-actions .save-continue-btn .btn, .mob-hipscan .action-edit-design .custom-design-page .design-options .design-actions .cancel-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer .launch-postermywall .btn {
  width: 100%;
  height: 45px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer .launch-postermywall-disabled .btn {
  width: 100%;
  height: 45px;
  background-color: #928e8c;
  border: none;
  border-radius: 9px;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .contact-info-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .contact-info-btn .btn, .detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .goto-menu-btn .btn {
  width: 180px;
  height: 45px;
  background-color: #f78e1e;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .common-input, .detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-name, .detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-price, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .common-input input, .detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-name input, .detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-price input, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .common-input input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .display-custom-image, .detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-img-upload, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design, .mob-hipscan .action-edit-design .custom-design-page .design-options .display-custom-design {
  position: relative;
  display: flex;
  width: 100%; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .display-custom-image input, .detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload input, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-img-upload input, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload input, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design input, .mob-hipscan .action-edit-design .custom-design-page .design-options .display-custom-design input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .display-custom-image .icon, .detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload .icon, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-img-upload .icon, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload .icon, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design .icon, .mob-hipscan .action-edit-design .custom-design-page .design-options .display-custom-design .icon {
  position: absolute;
  right: 0px;
  background-color: #f78e1e;
  color: white;
  height: 57px;
  width: 60px;
  padding: 9px;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #414042;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .display-custom-image .icon.left, .detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload .icon.left, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-img-upload .icon.left, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload .icon.left, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design .icon.left, .mob-hipscan .action-edit-design .custom-design-page .design-options .display-custom-design .icon.left {
  right: 60px;
  border-radius: 0;
  border-right: 0; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .display-custom-image span, .detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload span, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-img-upload span, .detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload span, .detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design span, .mob-hipscan .action-edit-design .custom-design-page .design-options .display-custom-design span {
  border: 1px solid;
  width: 100%;
  height: 57px;
  border-radius: 10px;
  align-items: center;
  padding-left: 20px;
  color: #414042;
  display: flex;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .display-video, .detail-my-hipscan .hipscan .action-part .action-edit-product .display-video {
  position: relative;
  display: flex;
  width: 100%; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .display-video input, .detail-my-hipscan .hipscan .action-part .action-edit-product .display-video input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 100px;
  border: 1px solid #414042;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .display-video .icon1, .detail-my-hipscan .hipscan .action-part .action-edit-product .display-video .icon1 {
  position: absolute;
  left: 0px;
  padding: 8px;
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .display-video .icon2, .detail-my-hipscan .hipscan .action-part .action-edit-product .display-video .icon2 {
  position: absolute;
  left: 45px;
  padding: 8px;
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action-edit-text .display-custom-text, .detail-my-hipscan .hipscan .action-part .action-edit-profile .textarea-input, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-description, .detail-my-hipscan .hipscan .action-part .action-edit-menu .textarea-input {
  width: 100%;
  height: 120px; }

.detail-my-hipscan .hipscan .action-part .action-edit-text .display-custom-text textarea, .detail-my-hipscan .hipscan .action-part .action-edit-profile .textarea-input textarea, .detail-my-hipscan .hipscan .action-part .action-edit-product .product-description textarea, .detail-my-hipscan .hipscan .action-part .action-edit-menu .textarea-input textarea {
  width: 100%;
  height: 100%;
  border-color: #414042;
  border-radius: 10px;
  padding: 10px 22px;
  outline: none; }

.mob-hipscan .action-edit-flyer h1, .mob-hipscan .action-edit-url h1, .mob-hipscan .action-edit-text h1, .mob-hipscan .action-edit-video h1, .mob-hipscan .action-edit-image h1, .mob-hipscan .action-edit-profile h1, .mob-hipscan .action-edit-product h1, .mob-hipscan .action-edit-menu h1, .mob-hipscan .action-edit-design h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042;
  font-size: 1.5rem;
  text-align: center; }

.mob-hipscan .action-edit-flyer .launch-postermywall .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-hipscan .action-edit-flyer .launch-postermywall-disabled .btn {
  background-color: #928e8c;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-hipscan .action-edit-flyer .save-continue-btn .btn, .mob-hipscan .action-edit-url .save-continue-btn .btn, .mob-hipscan .action-edit-text .save-continue-btn .btn, .mob-hipscan .action-edit-video .save-continue-btn .btn, .mob-hipscan .action-edit-image .save-continue-btn .btn, .mob-hipscan .action-edit-profile .save-continue-btn .btn, .mob-hipscan .action-edit-product .save-continue-btn .btn, .mob-hipscan .action-edit-menu .save-continue-btn .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-hipscan .action-edit-profile .contact-info-btn .btn, .mob-hipscan .action-edit-menu .btns .contact-info-btn .btn, .mob-hipscan .action-edit-menu .btns .goto-menu-btn .btn {
  background-color: #f78e1e;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 3rem;
  margin-bottom: 0;
  min-width: 13rem; }

.mob-hipscan .action-edit-profile .input-field .common-input, .mob-hipscan .action-edit-product .product .product-name, .mob-hipscan .action-edit-product .product .product-price, .mob-hipscan .action-edit-menu .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }

.mob-hipscan .action-edit-profile .input-field .common-input input, .mob-hipscan .action-edit-product .product .product-name input, .mob-hipscan .action-edit-product .product .product-price input, .mob-hipscan .action-edit-menu .input-field .common-input input {
  width: 100%;
  border-radius: 0.25rem;
  border-color: #414042;
  border: 1px solid #414042;
  outline: none;
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem; }

.mob-hipscan .action-edit-image .display-custom-image, .mob-hipscan .action-edit-profile .input-field .file-upload, .mob-hipscan .action-edit-product .product-img-upload, .mob-hipscan .action-edit-menu .input-field .file-upload {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }

.mob-hipscan .action-edit-image .display-custom-image .icon, .mob-hipscan .action-edit-profile .input-field .file-upload .icon, .mob-hipscan .action-edit-product .product-img-upload .icon, .mob-hipscan .action-edit-menu .input-field .file-upload .icon {
  position: absolute;
  right: 0px;
  background-color: #f78e1e;
  color: white;
  padding: 0.3rem;
  text-align: center;
  border: 1px solid #414042;
  cursor: pointer;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 2.3rem;
  width: 2.3rem; }

.mob-hipscan .action-edit-image .display-custom-image .icon.left, .mob-hipscan .action-edit-profile .input-field .file-upload .icon.left, .mob-hipscan .action-edit-product .product-img-upload .icon.left, .mob-hipscan .action-edit-menu .input-field .file-upload .icon.left {
  right: 2.3rem;
  border-radius: 0;
  border-right: 0; }

.mob-hipscan .action-edit-image .display-custom-image span, .mob-hipscan .action-edit-profile .input-field .file-upload span, .mob-hipscan .action-edit-product .product-img-upload span, .mob-hipscan .action-edit-menu .input-field .file-upload span {
  border: 1px solid;
  width: 100%;
  border-radius: 0.25rem;
  align-items: center;
  color: #414042;
  display: flex;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 0.8rem;
  height: auto;
  padding: 0.5rem 0.8rem; }

.mob-hipscan .action-edit-video .display-video, .mob-hipscan .action-edit-product .display-video {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }

.mob-hipscan .action-edit-video .display-video input, .mob-hipscan .action-edit-product .display-video input {
  width: 100%;
  border-radius: 0.25rem;
  border-color: #414042;
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  border: 1px solid #414042;
  outline: none; }

.mob-hipscan .action-edit-video .display-video .icon1, .mob-hipscan .action-edit-product .display-video .icon1 {
  position: absolute;
  right: 1.7rem;
  padding: 0.3rem;
  color: #f78e1e; }

.mob-hipscan .action-edit-video .display-video .icon2, .mob-hipscan .action-edit-product .display-video .icon2 {
  position: absolute;
  right: 0;
  padding: 0.3rem;
  color: #f78e1e; }

.mob-hipscan .action-edit-text .display-custom-text, .mob-hipscan .action-edit-profile .textarea-input, .mob-hipscan .action-edit-product .product-description, .mob-hipscan .action-edit-menu .textarea-input {
  width: 100%;
  height: 5rem;
  margin: 0.5rem 0;
  margin-bottom: 2rem; }

.mob-hipscan .action-edit-text .display-custom-text textarea, .mob-hipscan .action-edit-profile .textarea-input textarea, .mob-hipscan .action-edit-product .product-description textarea, .mob-hipscan .action-edit-menu .textarea-input textarea {
  width: 100%;
  height: 100%;
  border-color: #414042;
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem; }

.detail-my-hipscan .hipscan {
  position: relative;
  height: auto;
  width: 100%;
  display: flex; }

.detail-my-hipscan .hipscan .action-part {
  flex: 66%;
  height: 100%; }

.detail-my-hipscan .hipscan .action-part .action-image {
  width: 68%;
  height: 100%;
  position: absolute;
  top: 90px;
  opacity: 0.03;
  z-index: -1;
  object-fit: cover; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan {
  padding: 0px 80px; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan .upgrade-plan-content {
  padding: 10px;
  display: flex;
  background: #f78e1e;
  width: 100%; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan .upgrade-plan-content .remind-image {
  width: 20px;
  margin-right: 15px; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan .upgrade-plan-content p {
  margin-bottom: 0;
  color: #efefef; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan .upgrade-plan-content p .gotoUpgrade {
  text-decoration: none; }

.detail-my-hipscan .hipscan .action-part .upgrade-plan .upgrade-plan-content p span {
  color: white;
  font-weight: bold; }

.detail-my-hipscan .hipscan .action-part .copyUrl {
  display: flex;
  border-radius: 25px;
  justify-content: center;
  margin: 3px; }

.detail-my-hipscan .hipscan .action-part .copyUrl button:hover {
  background-color: #f58113;
  color: #fff;
  border-color: #fff; }

.detail-my-hipscan .hipscan .action-part .msg-upgrade {
  margin: 0 80px; }

.detail-my-hipscan .hipscan .action-part .msg-upgrade .alert-warning {
  background-color: #f58113;
  font-family: Montserrat;
  font-size: 18px !important;
  font-weight: 400;
  letter-spacing: 1.2px;
  color: #fff !important; }

.detail-my-hipscan .hipscan .action-part .msg-upgrade .alert-warning img {
  width: 22px;
  margin-right: 7px; }

.detail-my-hipscan .hipscan .action-part .msg-upgrade .alert-warning a {
  font-weight: bold;
  color: #fff; }

.detail-my-hipscan .hipscan .action-part .action {
  padding: 0px 80px;
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action .qr-code {
  flex: 40%;
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label {
  width: 100%;
  height: 40px;
  text-align: center;
  display: flex;
  position: relative; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label input {
  margin: auto; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label p {
  font-size: 15px;
  text-align: center;
  margin-bottom: 0rem;
  margin: auto; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label .edit {
  position: absolute;
  right: 6px;
  top: 6px; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label .edit .icon {
  color: #f78e1e;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label .save-cancel {
  position: absolute;
  right: -6px;
  top: 6px; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .label .save-cancel .icon {
  color: #f78e1e;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .qrcodeimage {
  justify-content: center;
  margin-bottom: 26px;
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .qrcodeimage img {
  width: 300px;
  height: 300px; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 {
  justify-content: center;
  display: flex;
  padding: 30px; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 .selected {
  border: 1px solid #f36e21; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 .black {
  background-color: black;
  width: 27px;
  height: 27px;
  margin: 5px 13px;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 .green {
  background-color: #00b300;
  width: 27px;
  height: 27px;
  margin: 5px 13px;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 .red {
  background-color: #820606;
  width: 27px;
  height: 27px;
  margin: 5px 13px;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .color4 .blue {
  background-color: #030369;
  width: 27px;
  height: 27px;
  margin: 5px 13px;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .download-btn {
  display: flex;
  justify-content: center; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .download-btn .btn {
  background-color: #f78e1e;
  border: none;
  margin: 0px 5px;
  width: 80px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 10px;
  outline: none; }

.copy-btn {
  background-color: #f78e1e;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 0px 5px;
  width: 90px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  outline: none; }

.copy-btn:hover {
  background-color: #b56611; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .action-btn {
  width: 100%;
  margin: 30px 0 10px 0;
  background: white;
  border-color: black;
  border-radius: 12px;
  height: 44px;
  color: #414042;
  text-align: center;
  font-size: 16px;
  font-weight: 600; }

.detail-my-hipscan .hipscan .action-part .action .qr-code .custom-design-upgrade {
  font-size: 12px;
  text-align: center; }

.detail-my-hipscan .hipscan .action-part .action .info-input {
  flex: 60%;
  height: 100%;
  padding: 10px; }

.detail-my-hipscan .hipscan .action-part .action .info-input h1 {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action .info-input h1 span {
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action .info-input p {
  font-size: 14px; }

.detail-my-hipscan .hipscan .action-part .action .info-input .action-btns {
  display: flex;
  flex-direction: column; }

.detail-my-hipscan .hipscan .action-part .action .info-input .action-btns .oauth-div {
  width: 100% !important; }

.detail-my-hipscan .hipscan .action-part .action .info-input .action-btns .action-btn {
  margin: 10px 0px;
  background: white;
  border-color: black;
  border-radius: 12px;
  height: 44px;
  color: #414042;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px; }

.detail-my-hipscan .hipscan .action-part .action .info-input .action-btns .action-btn-flyer {
  width: 100%;
  margin: 10px 0px;
  background: white;
  border-color: black;
  border-radius: 12px;
  height: 44px;
  color: #414042;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px; }

.detail-my-hipscan .hipscan .action-part .action .info-input .action-btns .active {
  border-color: #f36e21;
  border-width: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-url {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-url .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-url .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-url h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-url input {
  height: 57px;
  width: 560px;
  border-radius: 10px;
  border-color: #414042;
  display: block;
  padding-left: 22px;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-text {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-text .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-text .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-text h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-text .display-custom-text {
  height: 300px; }

.detail-my-hipscan .hipscan .action-part .action-edit-video {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-video .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-video h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-image {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-image .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-image h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile p {
  font-size: 15px;
  font-weight: 500; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile p span {
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field {
  justify-content: space-between;
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .file-upload {
  width: 47%; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .input-field .common-input {
  width: 47%; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .textarea-input {
  margin: 25px 0px; }

.detail-my-hipscan .hipscan .action-part .action-edit-profile .save-continue-btn {
  display: block; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer .flyer-designs {
  height: 50px;
  border-radius: 9px; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer p {
  font-size: 15px;
  font-weight: 500; }

.detail-my-hipscan .hipscan .action-part .action-edit-flyer p span {
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action-edit-product {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .product {
  display: flex;
  justify-content: space-between;
  padding: 25px 0px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-name {
  width: 62%; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .product .product-price {
  width: 34%; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .select {
  display: flex;
  background-color: white;
  width: 100%;
  height: 60px;
  margin-bottom: 3px;
  align-items: center;
  padding: 0px 20px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .select .name {
  flex: 0 0 80%; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .select .name p {
  padding-left: 10px;
  margin-bottom: 0px;
  display: inline;
  font-size: 15px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .select span {
  font-size: 15px; }

.detail-my-hipscan .hipscan .action-part .action-edit-product .product-description {
  margin: 25px 0px 0px 0px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu p {
  font-size: 15px;
  font-weight: 500; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu p span {
  color: #f78e1e; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field {
  justify-content: space-between;
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .file-upload {
  width: 47%; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .input-field .common-input {
  width: 47%; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .textarea-input {
  margin: 25px 0px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns {
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .contact-info-btn {
  margin-right: 20px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .goto-menu-btn {
  flex: 1; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select {
  display: flex; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select p {
  margin: auto;
  margin-right: 19px;
  font-size: 16px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select .theme {
  position: relative;
  display: flex;
  width: 120px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select .theme input {
  height: 48px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select .theme .icon {
  position: absolute;
  right: 0px;
  background-color: white;
  color: #414042;
  height: 48px;
  width: 50px;
  padding: 4px;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #414042;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select .theme .picker .sketch-picker {
  position: absolute;
  margin-top: 50px;
  margin-left: -220px; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .btns .theme-select .theme .picker .sketch-picker input {
  border-radius: 0px !important;
  height: 20px !important;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-menu .save-continue-btn {
  display: block; }

.detail-my-hipscan .hipscan .action-part .action-edit-design {
  padding: 18px 80px; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .goto {
  text-decoration: none;
  color: #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .goto svg {
  margin-bottom: 3px; }

.detail-my-hipscan .hipscan .action-part .action-edit-design h1 {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 21px 0px;
  color: #414042; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page {
  display: flex;
  flex-direction: row; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options {
  width: 60%;
  display: flex;
  flex-direction: column; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .icon-class {
  width: 60px;
  height: 60px;
  padding: 1px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .icon-class.active {
  border: 1px solid #f36e21; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .display-custom-design {
  width: 90%; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-theme {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-theme:first-child {
  width: 20%; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-pattern {
  display: flex;
  flex-direction: row;
  align-items: center; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-pattern:first-child {
  width: 20%; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-label {
  visibility: hidden;
  margin-top: 50px; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-label input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  display: block;
  padding-left: 22px;
  outline: none; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-label p {
  font-size: 10px;
  margin-top: 5px;
  padding-left: 22px; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-actions {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-actions .save-continue-btn {
  display: flex;
  justify-content: center; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-options .design-actions .cancel-btn {
  display: flex;
  justify-content: center;
  margin-left: 10px; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-preview {
  width: 40%;
  display: flex;
  flex-direction: column; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-preview p {
  align-self: center; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-preview .qr-design-preview {
  display: flex;
  justify-content: center; }

.detail-my-hipscan .hipscan .action-part .action-edit-design .custom-design-page .design-preview .qr-design-preview img {
  width: 300px;
  height: 300px; }

.detail-my-hipscan .hipscan .preview-part {
  flex: 34%;
  background-color: #efefef;
  position: relative; }

.detail-my-hipscan .hipscan .preview-part .title {
  text-align: center;
  margin-top: 35px;
  font-size: 19px;
  font-weight: 500;
  color: #414042; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone {
  width: 260px;
  position: absolute;
  left: 50%;
  margin-left: -130px;
  z-index: -1; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen {
  background-color: #000000cc;
  width: 360px;
  margin: auto;
  height: 640px;
  border-radius: 40px;
  border: 11px solid #080808;
  overflow: overlay;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  z-index: 99; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen::-webkit-scrollbar {
  display: none; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen::-webkit-scrollbar {
  width: 1px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen.with-out-gray {
  background-color: inherit; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen.with-out-gray h5 {
  color: black; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen video {
  border-radius: 40px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .background {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  opacity: 0.1;
  z-index: 22;
  pointer-events: none; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .prev-icon {
  margin-bottom: 16px;
  color: #f78e1e;
  display: flex;
  margin: auto;
  margin-top: 190px;
  margin-bottom: 16px;
  width: 100%; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen h5 {
  color: white;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  margin-top: 15px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen iframe {
  border-radius: 28px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .phone-footer,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .phone-footer,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .phone-footer {
  color: white;
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 10px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .phone-footer p,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .phone-footer p,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .phone-footer p {
  font-size: 7px;
  font-weight: bold;
  margin-bottom: 0px;
  padding-right: 6px;
  letter-spacing: 1px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .phone-footer img,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .phone-footer img,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .phone-footer img {
  width: 60px;
  height: 16px; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .preview-image-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .preview-video-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text-container .preview-text .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .preview-image-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .preview-video-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-image-container .preview-text .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .preview-image-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .preview-video-content .prev-icon,
.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-video-container .preview-text .prev-icon {
  margin-top: 0; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .preview-text {
  text-align: center;
  color: white;
  font-size: 11px;
  margin: 0px 10px;
  word-break: break-all; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .image-preview {
  width: calc(100% + 1px);
  height: 100%;
  object-fit: contain; }

.detail-my-hipscan .hipscan .preview-part .phone-frame .phone-screen .image-flyer-preview {
  width: calc(100% + 1px);
  height: 100%;
  object-fit: contain;
  display: flex;
  align-items: center; }

.detail-my-hipscan .statistics {
  margin: 0 7%; }

.letter-count {
  text-align: right; }

.mob-hipscan .upgrade-plan {
  padding: 10px; }

.mob-hipscan .upgrade-plan .upgrade-plan-content {
  padding: 10px;
  display: flex;
  background: #f78e1e;
  width: 100%; }

.mob-hipscan .upgrade-plan .upgrade-plan-content .remind-image {
  width: 20px;
  margin-right: 15px; }

.mob-hipscan .upgrade-plan .upgrade-plan-content p {
  margin-bottom: 0;
  color: #efefef;
  font-size: 0.9rem; }

.mob-hipscan .upgrade-plan .upgrade-plan-content p .gotoUpgrade {
  text-decoration: none; }

.mob-hipscan .upgrade-plan .upgrade-plan-content p span {
  color: white;
  font-weight: bold; }

.mob-hipscan .qr-img-section {
  margin: 0;
  margin-top: 1rem;
  justify-content: center;
  align-items: center; }

.mob-hipscan .qr-img-section .action-image {
  width: 100%; }

.mob-hipscan .qr-img-section .color4 {
  justify-content: space-between;
  display: flex; }

.mob-hipscan .qr-img-section .color4 .selected {
  border: 1px solid #f36e21; }

.mob-hipscan .qr-img-section .color4 .color-btn {
  width: 1.3rem;
  height: 1.3rem;
  margin: 0.3rem;
  cursor: pointer; }

.mob-hipscan .qr-img-section .color4 .color-btn.black {
  background-color: black; }

.mob-hipscan .qr-img-section .color4 .color-btn.green {
  background-color: #00b300; }

.mob-hipscan .qr-img-section .color4 .color-btn.red {
  background-color: #820606; }

.mob-hipscan .qr-img-section .color4 .color-btn.blue {
  background-color: #030369; }

.mob-hipscan .qr-img-section .download-btn .btn {
  background-color: #f78e1e;
  border: none;
  width: 100%;
  font-size: 0.7rem;
  font-weight: 500;
  outline: none;
  margin: 0.52rem 0; }

.mob-hipscan .qr-img-section .action-btn {
  width: 100%;
  margin: 0.4rem 0px;
  background: white !important;
  border-color: black !important;
  border-radius: 0.25rem;
  color: #414042 !important;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.5rem; }

.mob-hipscan .qr-img-section .custom-design-upgrade {
  font-size: 0.5rem;
  text-align: center; }

.mob-hipscan .info-input {
  flex: 60%;
  height: 100%;
  padding: 10px; }

.mob-hipscan .info-input h1 {
  font-size: 0.9rem;
  font-weight: 700;
  color: #414042;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 0; }

.mob-hipscan .info-input h1 span {
  color: #f78e1e; }

.mob-hipscan .info-input p {
  font-size: 0.5rem;
  text-align: center; }

.mob-hipscan .info-input .action-btns {
  display: flex;
  flex-direction: column; }

.mob-hipscan .info-input .action-btns .action-btn {
  margin: 0.4rem 0px;
  background: white !important;
  border-color: black !important;
  border-radius: 0.25rem;
  color: #414042 !important;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.5rem;
  padding-left: 1.5rem; }

.mob-hipscan .info-input .action-btns .active {
  border-color: #f36e21 !important;
  border-width: 3px; }

.mob-hipscan .action-edit-flyer {
  padding: 0 1rem; }

.mob-hipscan .action-edit-flyer p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-flyer p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-flyer .flyer-designs {
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-hipscan .action-edit-flyer .save-continue-btn {
  text-align: center;
  display: block; }

.mob-hipscan .action-edit-flyer .launch-postermywall {
  text-align: center;
  display: block; }

.mob-hipscan .action-edit-flyer .launch-postermywall-disabled {
  text-align: center;
  display: block; }

.mob-hipscan .action-edit-url {
  padding: 0 1rem; }

.mob-hipscan .action-edit-url input {
  width: 100%;
  border-radius: 0.25rem;
  display: block;
  outline: none;
  border-width: 0.01rem;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 0.7rem; }

.mob-hipscan .action-edit-url .save-continue-btn {
  text-align: center; }

.mob-hipscan .action-edit-text {
  padding: 0 1rem; }

.mob-hipscan .action-edit-text p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-text p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-text .display-custom-text {
  height: 10rem; }

.mob-hipscan .action-edit-text .save-continue-btn {
  text-align: center; }

.mob-hipscan .action-edit-video {
  padding: 0 1rem; }

.mob-hipscan .action-edit-video .save-continue-btn {
  text-align: center; }

.mob-hipscan .action-edit-image {
  padding: 0 1rem; }

.mob-hipscan .action-edit-image .save-continue-btn {
  text-align: center; }

.mob-hipscan .action-edit-profile {
  padding: 0 1rem; }

.mob-hipscan .action-edit-profile p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-profile p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-profile .input-field {
  justify-content: space-between;
  display: block; }

.mob-hipscan .action-edit-profile .contact-info-btn {
  text-align: center; }

.mob-hipscan .action-edit-profile .save-continue-btn {
  text-align: center;
  display: block; }

.mob-hipscan .action-edit-product {
  padding: 0 1rem; }

.mob-hipscan .action-edit-product p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-product p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-product .product {
  display: flex;
  justify-content: space-between; }

.mob-hipscan .action-edit-product .product .product-name {
  width: 62%; }

.mob-hipscan .action-edit-product .product .product-price {
  width: 34%; }

.mob-hipscan .action-edit-product .select {
  display: flex;
  background-color: white;
  width: 100%;
  margin: 0.5rem 0;
  align-items: center;
  margin-bottom: 1rem; }

.mob-hipscan .action-edit-product .select .name {
  flex: 0 0 80%; }

.mob-hipscan .action-edit-product .select .name p {
  padding-left: 10px;
  display: inline;
  font-size: 0.8rem; }

.mob-hipscan .action-edit-product .select span {
  font-size: 15px; }

.mob-hipscan .action-edit-product .save-continue-btn {
  text-align: center; }

.mob-hipscan .action-edit-menu {
  padding: 0 1rem; }

.mob-hipscan .action-edit-menu p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-menu p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-menu .btns .contact-info-btn {
  text-align: center; }

.mob-hipscan .action-edit-menu .btns .goto-menu-btn {
  text-align: center; }

.mob-hipscan .action-edit-menu .btns .theme-select {
  display: flex; }

.mob-hipscan .action-edit-menu .btns .theme-select p {
  margin: auto;
  margin-right: 19px;
  font-size: 16px; }

.mob-hipscan .action-edit-menu .btns .theme-select .theme {
  position: relative;
  display: flex;
  width: 120px; }

.mob-hipscan .action-edit-menu .btns .theme-select .theme input {
  height: 48px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  padding-left: 22px;
  border: 1px solid #414042; }

.mob-hipscan .action-edit-menu .btns .theme-select .theme .icon {
  position: absolute;
  right: 0px;
  background-color: white;
  color: #414042;
  height: 48px;
  width: 50px;
  padding: 4px;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #414042;
  cursor: pointer; }

.mob-hipscan .action-edit-menu .btns .theme-select .theme .picker .sketch-picker {
  position: absolute;
  margin-top: 50px;
  margin-left: -220px; }

.mob-hipscan .action-edit-menu .btns .theme-select .theme .picker .sketch-picker input {
  border-radius: 0px !important;
  height: 20px !important;
  outline: none; }

.mob-hipscan .action-edit-menu .save-continue-btn {
  text-align: center;
  display: block; }

.mob-hipscan .action-edit-design {
  padding: 0 1rem; }

.mob-hipscan .action-edit-design p {
  font-size: 0.7rem;
  text-align: center;
  font-weight: 500; }

.mob-hipscan .action-edit-design p span {
  color: #f78e1e; }

.mob-hipscan .action-edit-design .custom-design-page {
  display: flex;
  flex-direction: column; }

.mob-hipscan .action-edit-design .custom-design-page .design-options {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .icon-class {
  width: 60px;
  height: 60px;
  padding: 1px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .icon-class.active {
  border: 1px solid #f36e21; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-theme {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-theme:first-child {
  width: 20%; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-color {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-self: flex-start; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-pattern {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-pattern:first-child {
  width: 20%; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-label {
  visibility: hidden;
  margin-top: 50px; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-label input {
  height: 57px;
  width: 100%;
  border-radius: 10px;
  border-color: #414042;
  display: block;
  padding-left: 22px;
  outline: none; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-label p {
  font-size: 10px;
  margin-top: 5px;
  padding-left: 22px;
  text-align: left; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-actions .save-continue-btn {
  display: flex;
  justify-content: center;
  margin-left: 10px; }

.mob-hipscan .action-edit-design .custom-design-page .design-options .design-actions .cancel-btn {
  display: flex;
  justify-content: center;
  margin-left: 10px; }

.mob-hipscan .action-edit-design .custom-design-page .design-preview {
  width: 100%;
  display: flex;
  flex-direction: column; }

.mob-hipscan .action-edit-design .custom-design-page .design-preview p {
  align-self: center; }

.mob-hipscan .action-edit-design .custom-design-page .design-preview .qr-design-preview {
  display: flex;
  justify-content: center; }

.mob-hipscan .action-edit-design .custom-design-page .design-preview .qr-design-preview img {
  width: 300px;
  height: 300px; }

@media only screen and (max-width: 1200px) {
  .container,
  .container-md,
  .container-sm {
    width: 100% !important;
    max-width: 960px !important; }
  .homepage .home .qrcode img {
    width: 90% !important;
    height: auto !important; }
  .homepage .home .home-title-container .title {
    font-size: 30px; }
  .signup .signup-section .home {
    display: block; }
  .signup .signup-section .home .input .info-input .form-group {
    width: 100%; }
  .login-home .login-section .container .home {
    padding: 2rem; }
  .detail-my-hipscan .hipscan .action-part {
    flex: 100%; }
  .detail-my-hipscan .hipscan .action-part .action .qr-code {
    padding: 0; }
  .detail-my-hipscan .hipscan .action-part .action .info-input .action-btns .action-btn {
    padding-left: 0.5rem;
    font-size: 10px; }
  .detail-my-hipscan .hipscan .preview-part {
    display: none; } }

@media (max-width: 1200px) {
  .detail-my-hipscan .hipscan .action-part .msg-upgrade .alert-warning {
    font-size: 20px; } }

@media (max-width: 991px) {
  .detail-my-hipscan .hipscan .action-part .msg-upgrade .alert-warning {
    font-size: 18px; } }

@media (max-width: 767px) {
  .detail-my-hipscan .msg-upgrade {
    margin: 0 15px; }
  .detail-my-hipscan .msg-upgrade .alert-warning {
    background-color: #f58113;
    font-family: Montserrat;
    font-size: 14px !important;
    font-weight: 400;
    letter-spacing: 1.2px;
    color: #fff !important; }
  .detail-my-hipscan .msg-upgrade .alert-warning img {
    width: 16px;
    margin-right: 5px; }
  .detail-my-hipscan .msg-upgrade .alert-warning a {
    font-weight: bold;
    color: #fff; } }
