.menu-category {
  background-color: #efefef;
  width: 100%;
  margin: auto;
  height: 100vh;
  max-width: 415px;
  max-height: 823px;
  position: relative; }
  .menu-category .category-phone {
    width: 100%;
    height: 25%; }
    .menu-category .category-phone .restaurant-photo {
      position: absolute;
      width: 100%;
      height: 25%;
      object-fit: cover;
      justify-content: center; }
    .menu-category .category-phone .backdrop {
      position: absolute;
      width: 100%;
      height: 25%;
      background: #000000a6; }
    .menu-category .category-phone .back-btn {
      color: white;
      height: 16%;
      padding: 8px 8px 2px 8px;
      cursor: pointer;
      position: relative; }
    .menu-category .category-phone .avatar-name {
      width: 100%;
      height: 84%;
      display: flex;
      position: relative; }
      .menu-category .category-phone .avatar-name .avatar {
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        border: 2px solid #f36e21;
        margin-left: 20px;
        margin-right: 20px; }
      .menu-category .category-phone .avatar-name .name {
        color: white;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-left: 10px; }
        .menu-category .category-phone .avatar-name .name p {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px; }
        .menu-category .category-phone .avatar-name .name span {
          font-size: 13px; }
    .menu-category .category-phone .title {
      width: 100%;
      height: 50px;
      background: white;
      margin-bottom: 3px;
      padding-left: 20px;
      align-items: center;
      display: flex; }
      .menu-category .category-phone .title p {
        margin-bottom: 0px;
        font-weight: bold;
        letter-spacing: 1px; }
    .menu-category .category-phone .foods {
      width: 100%;
      height: 180px;
      background: white;
      margin-bottom: 15px;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between; }
      .menu-category .category-phone .foods .food {
        height: 100%;
        width: 31%; }
        .menu-category .category-phone .foods .food .image {
          width: 100%;
          height: 100px;
          border-radius: 7px;
          object-fit: cover; }
        .menu-category .category-phone .foods .food p {
          font-size: 10px;
          padding-top: 13px;
          margin-bottom: 5px;
          text-align: center; }
        .menu-category .category-phone .foods .food h5 {
          font-size: 12px;
          font-weight: bold;
          text-align: center; }
    .menu-category .category-phone .phone-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; }
      .menu-category .category-phone .phone-footer p {
        font-size: 9px;
        font-weight: bold;
        color: black;
        margin-bottom: 0px;
        padding-right: 6px;
        letter-spacing: 1px; }
      .menu-category .category-phone .phone-footer img {
        width: 65px;
        height: 20px; }
