.textarea-input {
  width: 100%;
  height: 120px;
}

.textarea-input textarea {
  max-height: 132px;
  width: 100%;
  height: 100%;
  border-color: #414042;
  border-radius: 10px;
  padding: 10px 22px;
  outline: none; 
}

.error-text {
  color: red;
  text-align: left;
  margin-bottom: 18px; }
