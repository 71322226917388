.modal-container .modal-content .modal-body .input-field .save-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.modal-container .modal-content .modal-body .input-field .email-phone .email, .modal-container .modal-content .modal-body .input-field .email-phone .phone, .modal-container .modal-content .modal-body .input-field .address {
  position: relative;
  display: flex;
  width: 100%; }
  .modal-container .modal-content .modal-body .input-field .email-phone .email input, .modal-container .modal-content .modal-body .input-field .email-phone .phone input, .modal-container .modal-content .modal-body .input-field .address input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }

.modal-container .modal-content .modal-body .input-field .image-upload {
  position: relative;
  display: flex;
  width: 100%; }
  .modal-container .modal-content .modal-body .input-field .image-upload input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }
  .modal-container .modal-content .modal-body .input-field .image-upload .icon {
    position: absolute;
    right: 0px;
    background-color: #f78e1e;
    color: white;
    height: 57px;
    width: 60px;
    padding: 9px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #414042;
    cursor: pointer; }
    .modal-container .modal-content .modal-body .input-field .image-upload .icon.left {
      right: 60px;
      border-radius: 0;
      border-right: 0; }
  .modal-container .modal-content .modal-body .input-field .image-upload span {
    border: 1px solid;
    width: 100%;
    height: 57px;
    border-radius: 10px;
    align-items: center;
    padding-left: 20px;
    color: #414042;
    display: flex;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important; }

.modal-container .modal-content .modal-body .input-field .video {
  position: relative;
  display: flex;
  width: 100%; }
  .modal-container .modal-content .modal-body .input-field .video input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 100px;
    border: 1px solid #414042;
    outline: none; }
  .modal-container .modal-content .modal-body .input-field .video .icon1 {
    position: absolute;
    left: 0px;
    padding: 8px;
    color: #f78e1e; }
  .modal-container .modal-content .modal-body .input-field .video .icon2 {
    position: absolute;
    left: 45px;
    padding: 8px;
    color: #f78e1e; }

.modal-container .modal-content .modal-body .input-field .website-facebook .website, .modal-container .modal-content .modal-body .input-field .website-facebook .facebook, .modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram, .modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter {
  position: relative;
  display: flex;
  width: 100%; }
  .modal-container .modal-content .modal-body .input-field .website-facebook .website input, .modal-container .modal-content .modal-body .input-field .website-facebook .facebook input, .modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram input, .modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 55px;
    border: 1px solid #414042;
    outline: none; }
  .modal-container .modal-content .modal-body .input-field .website-facebook .website .icon, .modal-container .modal-content .modal-body .input-field .website-facebook .facebook .icon, .modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram .icon, .modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter .icon {
    position: absolute;
    left: 0px;
    background: #f78e1e;
    color: white;
    border-radius: 50%;
    padding: 6px;
    margin: 13px; }

.mob-modal-container .modal-content .modal-body .input-field .save-btn .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-modal-container .modal-content .modal-body .input-field .email-phone .email, .mob-modal-container .modal-content .modal-body .input-field .email-phone .phone, .mob-modal-container .modal-content .modal-body .input-field .address {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-modal-container .modal-content .modal-body .input-field .email-phone .email input, .mob-modal-container .modal-content .modal-body .input-field .email-phone .phone input, .mob-modal-container .modal-content .modal-body .input-field .address input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    border: 1px solid #414042;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.mob-modal-container .modal-content .modal-body .input-field .image-upload {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-modal-container .modal-content .modal-body .input-field .image-upload .icon {
    position: absolute;
    right: 0px;
    background-color: #f78e1e;
    color: white;
    padding: 0.3rem;
    text-align: center;
    border: 1px solid #414042;
    cursor: pointer;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    height: 2.3rem;
    width: 2.3rem; }
    .mob-modal-container .modal-content .modal-body .input-field .image-upload .icon.left {
      right: 2.3rem;
      border-radius: 0;
      border-right: 0; }
  .mob-modal-container .modal-content .modal-body .input-field .image-upload span {
    border: 1px solid;
    width: 100%;
    border-radius: 0.25rem;
    align-items: center;
    color: #414042;
    display: flex;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-size: 0.8rem;
    height: auto;
    padding: 0.5rem 0.8rem; }

.mob-modal-container .modal-content .modal-body .input-field .video {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-modal-container .modal-content .modal-body .input-field .video input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
    border: 1px solid #414042;
    outline: none; }
  .mob-modal-container .modal-content .modal-body .input-field .video .icon1 {
    position: absolute;
    right: 1.7rem;
    padding: 0.3rem;
    color: #f78e1e; }
  .mob-modal-container .modal-content .modal-body .input-field .video .icon2 {
    position: absolute;
    right: 0;
    padding: 0.3rem;
    color: #f78e1e; }

.mob-modal-container .modal-content .modal-body .input-field .website-facebook .website, .mob-modal-container .modal-content .modal-body .input-field .website-facebook .facebook, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-modal-container .modal-content .modal-body .input-field .website-facebook .website input, .mob-modal-container .modal-content .modal-body .input-field .website-facebook .facebook input, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram input, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
    border: 1px solid #414042;
    outline: none; }
  .mob-modal-container .modal-content .modal-body .input-field .website-facebook .website .icon, .mob-modal-container .modal-content .modal-body .input-field .website-facebook .facebook .icon, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram .icon, .mob-modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter .icon {
    position: absolute;
    right: 0px;
    background: #f78e1e;
    color: white;
    border-radius: 50%;
    padding: 0.3rem;
    margin: 0.407rem; }

.modal-container {
  max-width: 60% !important;
  margin-top: 116px !important; }
  .modal-container .modal-content .modal-header {
    padding: 20px 30px; }
    .modal-container .modal-content .modal-header .modal-title {
      font-size: 28px;
      font-weight: 700; }
    .modal-container .modal-content .modal-header .close {
      font-size: 2.5rem; }
  .modal-container .modal-content .modal-body {
    padding: 20px 40px;
    display: flex; }
    .modal-container .modal-content .modal-body .input-field {
      width: 75%; }
      .modal-container .modal-content .modal-body .input-field .image-upload {
        padding: 15px 0px; }
      .modal-container .modal-content .modal-body .input-field .email-phone {
        padding: 12px 0px;
        display: flex;
        justify-content: space-between; }
        .modal-container .modal-content .modal-body .input-field .email-phone .email {
          width: 47%; }
        .modal-container .modal-content .modal-body .input-field .email-phone .phone {
          width: 47%; }
      .modal-container .modal-content .modal-body .input-field .website-facebook {
        padding: 12px 0px;
        display: flex;
        justify-content: space-between; }
        .modal-container .modal-content .modal-body .input-field .website-facebook .website {
          width: 47%; }
        .modal-container .modal-content .modal-body .input-field .website-facebook .facebook {
          width: 47%; }
      .modal-container .modal-content .modal-body .input-field .instagram-twitter {
        padding: 12px 0px;
        display: flex;
        justify-content: space-between; }
        .modal-container .modal-content .modal-body .input-field .instagram-twitter .instagram {
          width: 47%; }
        .modal-container .modal-content .modal-body .input-field .instagram-twitter .twitter {
          width: 47%; }
      .modal-container .modal-content .modal-body .input-field .select {
        display: flex;
        background-color: white;
        width: 100%;
        height: 60px;
        margin-bottom: 3px;
        align-items: center;
        padding: 0px 20px; }
        .modal-container .modal-content .modal-body .input-field .select .name {
          flex: 0 0 80%; }
          .modal-container .modal-content .modal-body .input-field .select .name p {
            padding-left: 10px;
            margin-bottom: 0px;
            display: inline;
            font-size: 15px; }
        .modal-container .modal-content .modal-body .input-field .select span {
          font-size: 15px; }
      .modal-container .modal-content .modal-body .input-field .address {
        padding: 12px 0px; }
      .modal-container .modal-content .modal-body .input-field .video {
        padding: 12px 0px; }
    .modal-container .modal-content .modal-body .image-preview {
      width: 25%;
      padding: 40px 20px 40px 40px; }
      .modal-container .modal-content .modal-body .image-preview .image {
        width: 100%;
        height: 150px;
        background: #efefef;
        display: flex;
        align-items: center; }
        .modal-container .modal-content .modal-body .image-preview .image .selectedImage {
          width: 100%;
          height: 100%;
          object-fit: contain; }
        .modal-container .modal-content .modal-body .image-preview .image p {
          text-align: center; }

.create-hipscan-modal {
  max-width: 500px !important; }

.mob-modal-container .modal-content .modal-header {
  justify-content: center;
  align-items: center; }
  .mob-modal-container .modal-content .modal-header .modal-title {
    font-size: 1.3rem;
    font-weight: 700; }
  .mob-modal-container .modal-content .modal-header .close {
    font-size: 2.5rem; }

.mob-modal-container .modal-content .modal-body {
  padding: 1rem;
  display: flex; }
  .mob-modal-container .modal-content .modal-body .input-field {
    width: 100%; }
    .mob-modal-container .modal-content .modal-body .input-field .select {
      display: flex;
      background-color: white;
      width: 100%;
      height: 60px;
      margin-bottom: 3px;
      align-items: center;
      padding: 0px 20px; }
      .mob-modal-container .modal-content .modal-body .input-field .select .name p {
        padding-left: 10px;
        margin-bottom: 0px;
        display: inline;
        font-size: 0.8rem; }
      .mob-modal-container .modal-content .modal-body .input-field .select span {
        font-size: 15px; }
    .mob-modal-container .modal-content .modal-body .input-field .save-btn {
      text-align: center; }
