.change-email-modal .modal-content .modal-body .body-container .change-bottom-email .btn {
  width: 180px;
  height: 45px;
  background-color: #f78e1e;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: none; }

.change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email {
  position: relative;
  display: flex;
  width: 100%; }
  .change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }

.mob-change-email-modal .modal-content .modal-body .body-container .change-bottom-email .btn {
  background-color: #f78e1e;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 3rem;
  margin-bottom: 0;
  min-width: 13rem; }

.mob-change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    border: 1px solid #414042;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.change-email-modal {
  max-width: 60% !important;
  margin-top: 116px !important; }
  .change-email-modal .modal-content .modal-header {
    padding: 30px 40px 30px; }
    .change-email-modal .modal-content .modal-header .modal-title {
      font-size: 25px;
      font-weight: 500; }
  .change-email-modal .modal-content .modal-body {
    padding: 20px 60px 40px; }
    .change-email-modal .modal-content .modal-body .body-container {
      display: block;
      width: 100%; }
      .change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email {
        padding-left: 2.5% !important;
        width: 95% !important; }
      .change-email-modal .modal-content .modal-body .body-container .change-bottom-email {
        display: flex;
        justify-content: center;
        padding-top: 2%; }
        .change-email-modal .modal-content .modal-body .body-container .change-bottom-email p {
          color: #f78e1e;
          cursor: pointer; }
        .change-email-modal .modal-content .modal-body .body-container .change-bottom-email .change-btn {
          background-color: #f36e21; }

.mob-change-email-modal .modal-content .modal-header {
  justify-content: center;
  align-items: center; }
  .mob-change-email-modal .modal-content .modal-header .modal-title {
    font-size: 1.3rem;
    font-weight: 700; }

.mob-change-email-modal .modal-content .modal-body {
  padding: 1rem;
  display: flex; }
  .mob-change-email-modal .modal-content .modal-body .body-container {
    display: block;
    width: 100%; }
    .mob-change-email-modal .modal-content .modal-body .body-container .input-field .common-input-email {
      padding: 0px; }
    .mob-change-email-modal .modal-content .modal-body .body-container .change-bottom-email {
      padding-right: 20%;
      justify-content: space-between;
      display: flex; }
      .mob-change-email-modal .modal-content .modal-body .body-container .change-bottom-email .change-btn {
        width: 250px;
        background-color: #f36e21; }
