$base-color: #f36e21;
$base-color-light:#f78e1e;
$font-color:#414042;
$gray-light:#efefef;
$boder-color: #a8a9a9;

%save-continue-btn {
    .btn {
        width: 280px;
        height: 57px;
        background-color: $base-color;
        border: none;
        border-radius: 9px;
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 25px 0px;
        box-shadow: none;
    }
}

%launch-postermywall {
    .btn {
        width: 100%;
        height: 45px;
        background-color: #f36e21;
        border: none;
        border-radius: 9px;
        font-size: 20px;
        letter-spacing: 3px;
        font-weight: 500;
        margin: 25px 0px;
        box-shadow: none;
    }
}

%launch-postermywall-disabled {
    .btn {
        width: 100%;
        height: 45px;
        background-color: #928e8c;
        border: none;
        border-radius: 9px;
        font-size: 20px;
        letter-spacing: 3px;
        font-weight: 500;
        margin: 25px 0px;
        box-shadow: none;
    }
}

%contact-info-btn {
    .btn {
        width: 180px;
        height: 45px;
        background-color: $base-color-light;
        border: none;
        border-radius: 9px;
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 500;
        box-shadow: none;
    }
}

%add-btn {
    width: 100%;
    .btn {
        width: 100%;
        height: 57px;
        background-color: white;
        border-radius: 9px;
        border: 2px dashed;
        font-size: 16px;
        color: $base-color-light;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 12px 0px;
        text-align: left;
        padding-left: 30px;
        box-shadow: none;
    }
}

%edit-delete-btn {
    background: $base-color-light;
    height: 57px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 12px 0px;
    display: flex;
    p {
        color: white;
        margin-bottom: 0px;
        font-weight: 600;
        flex: 1;
        font-size: 14px;
    }
    .edit {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
    .trash {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
}

%edit-delete-save-cancel-btn {
    background: $base-color-light;
    height: 57px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 12px 0px;
    display: flex;
    p {
        color: white;
        margin-bottom: 0px;
        font-weight: 600;
        flex: 1;
        font-size: 14px;
    }
    .edit,
    .trash,
    .save,
    .cancel {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
}

%common-input {
    position: relative;
    display: flex;
    width: 100%;
    input {
        height: 57px;
        width: 100%;
        border-radius: 10px;
        border-color: $font-color;
        padding-left: 22px;
        border: 1px solid $font-color;
        outline: none;
    }
}

%file-upload-input {
    position: relative;
    display: flex;
    width: 100%;
    input {
        height: 57px;
        width: 100%;
        border-radius: 10px;
        border-color: $font-color;
        padding-left: 22px;
        border: 1px solid $font-color;
        outline: none;
    }
    .icon {
        position: absolute;
        right: 0px;
        background-color: $base-color-light;
        color: white;
        height: 57px;
        width: 60px;
        padding: 9px;
        text-align: center;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid $font-color;
        cursor: pointer;
        &.left {
            right: 60px;
            border-radius: 0;
            border-right: 0;
        }
    }
    span {
        border: 1px solid;
        width: 100%;
        height: 57px;
        border-radius: 10px;
        align-items: center;
        padding-left: 20px;
        color: $font-color;
        display: flex;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }
}

%video-url-input {
    position: relative;
    display: flex;
    width: 100%;
    input {
        height: 57px;
        width: 100%;
        border-radius: 10px;
        border-color: $font-color;
        padding-left: 100px;
        border: 1px solid $font-color;
        outline: none;
    }
    .icon1 {
        position: absolute;
        left: 0px;
        padding: 8px;
        color: $base-color-light;
    }
    .icon2 {
        position: absolute;
        left: 45px;
        padding: 8px;
        color: $base-color-light;
    }
}

%social-url-input {
    position: relative;
    display: flex;
    width: 100%;
    input {
        height: 57px;
        width: 100%;
        border-radius: 10px;
        border-color: $font-color;
        padding-left: 55px;
        border: 1px solid $font-color;
        outline: none;
    }
    .icon {
        position: absolute;
        left: 0px;
        background: $base-color-light;
        color: white;
        border-radius: 50%;
        padding: 6px;
        margin: 13px;
    }
}

%textarea-input {
    width: 100%;
    height: 120px;
    textarea {
        width: 100%;
        height: 100%;
        border-color: $font-color;
        border-radius: 10px;
        padding: 10px 22px;
        outline: none;
    }
}

%mob-action-title-h1 {
    font-weight: bold;
    letter-spacing: 1px;
    margin: 21px 0px;
    color: $font-color;
    font-size: 1.5rem;
    text-align: center;
}

%mob-launch-postermywall {
    .btn {
        background-color: $base-color;
        border: none;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 1rem;
        box-shadow: none;
        padding: 0.5rem 1.5rem;
        min-width: 13rem;
    }
}

%mob-launch-postermywall-disabled {
    .btn {
        background-color: #928e8c;
        border: none;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 1rem;
        box-shadow: none;
        padding: 0.5rem 1.5rem;
        min-width: 13rem;
    }
}

%mob-save-continue-btn {
    .btn {
        background-color: $base-color;
        border: none;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 1rem;
        box-shadow: none;
        padding: 0.5rem 1.5rem;
        min-width: 13rem;
    }
}

%mob-contact-info-btn {
    .btn {
        background-color: $base-color-light;
        border: none;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 1rem;
        box-shadow: none;
        padding: 0.5rem 3rem;
        margin-bottom: 0;
        min-width: 13rem;
    }
}

%mob-add-btn {
    width: 100%;
    .btn {
        width: 100%;
        background-color: white;
        border-radius: 0.25rem;
        border: 2px dashed;
        font-size: 0.7rem;
        color: $base-color-light;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 0;
        text-align: left;
        height: 2.4rem;
        box-shadow: none;
    }
}

%mob-edit-delete-btn {
    background: $base-color-light;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    height: 2.4rem;
    margin: 12px 0px;
    display: flex;
    p {
        color: white;
        margin-bottom: 0px;
        font-weight: 600;
        flex: 1;
        font-size: 0.7rem;
        padding-left: 1rem;
    }
    .edit {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
    .trash {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
}

%mob-edit-delete-save-cancel-btn {
    background: $base-color-light;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    height: 2.4rem;
    margin: 12px 0px;
    display: flex;
    p {
        color: white;
        margin-bottom: 0px;
        font-weight: 600;
        flex: 1;
        font-size: 0.7rem;
        padding-left: 1rem;
    }
    .edit,
    .trash,
    .save,
    .cancel {
        color: white;
        margin-right: 15px;
        cursor: pointer;
    }
}

%mob-common-input {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    input {
        width: 100%;
        border-radius: 0.25rem;
        border-color: $font-color;
        border: 1px solid $font-color;
        outline: none;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }
}

%mob-file-upload-input {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    .icon {
        position: absolute;
        right: 0px;
        background-color: $base-color-light;
        color: white;
        padding: 0.3rem;
        text-align: center;
        border: 1px solid $font-color;
        cursor: pointer;
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        height: 2.3rem;
        width: 2.3rem;
        &.left {
            right: 2.3rem;
            border-radius: 0;
            border-right: 0;
        }
    }
    span {
        border: 1px solid;
        width: 100%;
        border-radius: 0.25rem;
        align-items: center;
        color: $font-color;
        display: flex;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        font-size: 0.8rem;
        height: auto;
        padding: 0.5rem 0.8rem;
    }
}

%mob-video-url-input {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    input {
        width: 100%;
        border-radius: 0.25rem;
        border-color: $font-color;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
        border: 1px solid $font-color;
        outline: none;
    }
    .icon1 {
        position: absolute;
        right: 1.7rem;
        padding: 0.3rem;
        color: $base-color-light;
    }
    .icon2 {
        position: absolute;
        right: 0;
        padding: 0.3rem;
        color: $base-color-light;
    }
}

%mob-social-url-input {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    input {
        width: 100%;
        border-radius: 0.25rem;
        border-color: $font-color;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
        border: 1px solid $font-color;
        outline: none;
    }
    .icon {
        position: absolute;
        right: 0px;
        background: $base-color-light;
        color: white;
        border-radius: 50%;
        padding: 0.3rem;
        margin: 0.407rem;
    }
}

%mob-textarea-input {
    width: 100%;
    height: 5rem;
    margin: 0.5rem 0;
    margin-bottom: 2rem;
    textarea {
        width: 100%;
        height: 100%;
        border-color: $font-color;
        border-radius: 0.25rem;
        outline: none;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }
}
