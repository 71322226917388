.ant-result {
  width: 50%;
  margin: 60px auto;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #f8f8f8;
}
.success-wrap {
  padding: 15px;
}
.notify-success {
  padding: 80px 20px;
  width: 100%;
  max-width: 530px;
  margin: 60px auto;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #f8f8f8;
  text-align: center;
  h1 {
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
  h2 {
    font-weight: bold;
    font-size: 28px;
    color: #f36e21;
    margin-bottom: 30px;
  }
  .btn-proceed {
    margin-top: 30px;
    text-transform: uppercase;
    border: none;
    background-color: #f36e21;
    color: #fff;
    height: auto;
    padding: 8px 30px;
    border-radius: 5px;
    font-weight: 600;
  }
  @media (max-width: 450px) {
    padding: 60px 15px;
    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
    }
    h2 {
      font-size: 26px;
    }
    .btn-proceed {
      padding: 7px 14px;
    }
  }
}
