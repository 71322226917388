.restaurant-menu-modal-container .modal-content .modal-body .body-container .save-btn .btn {
  width: 280px;
  height: 57px;
  background-color: #f36e21;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px;
  box-shadow: none; }

.restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add {
  width: 100%; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add .btn, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add .btn {
    width: 100%;
    height: 57px;
    background-color: white;
    border-radius: 9px;
    border: 2px dashed;
    font-size: 16px;
    color: #f78e1e;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 12px 0px;
    text-align: left;
    padding-left: 30px;
    box-shadow: none; }

.restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category {
  background: #f78e1e;
  height: 57px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 12px 0px;
  display: flex; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category p, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active p, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category p {
    color: white;
    margin-bottom: 0px;
    font-weight: 600;
    flex: 1;
    font-size: 14px; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .edit, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active .edit, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .edit {
    color: white;
    margin-right: 15px;
    cursor: pointer; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .trash, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active .trash, .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .trash {
    color: white;
    margin-right: 15px;
    cursor: pointer; }

.restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active {
  background: #f78e1e;
  height: 57px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 12px 0px;
  display: flex; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active p {
    color: white;
    margin-bottom: 0px;
    font-weight: 600;
    flex: 1;
    font-size: 14px; }
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .edit,
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .trash,
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .save,
  .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .cancel {
    color: white;
    margin-right: 15px;
    cursor: pointer; }

.mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .save-btn .btn {
  background-color: #f36e21;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  min-width: 13rem; }

.mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add {
  width: 100%; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add .btn, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add .btn {
    width: 100%;
    background-color: white;
    border-radius: 0.25rem;
    border: 2px dashed;
    font-size: 0.7rem;
    color: #f78e1e;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 0;
    text-align: left;
    height: 2.4rem;
    box-shadow: none; }

.mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category {
  background: #f78e1e;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  height: 2.4rem;
  margin: 12px 0px;
  display: flex; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category p, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active p, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category p {
    color: white;
    margin-bottom: 0px;
    font-weight: 600;
    flex: 1;
    font-size: 0.7rem;
    padding-left: 1rem; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .edit, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active .edit, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .edit {
    color: white;
    margin-right: 15px;
    cursor: pointer; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .trash, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active .trash, .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .trash {
    color: white;
    margin-right: 15px;
    cursor: pointer; }

.mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active {
  background: #f78e1e;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  height: 2.4rem;
  margin: 12px 0px;
  display: flex; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active p {
    color: white;
    margin-bottom: 0px;
    font-weight: 600;
    flex: 1;
    font-size: 0.7rem;
    padding-left: 1rem; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .edit,
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .trash,
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .save,
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .cancel {
    color: white;
    margin-right: 15px;
    cursor: pointer; }

.restaurant-menu-modal-container {
  max-width: 60% !important;
  margin-top: 116px !important; }
  .restaurant-menu-modal-container .modal-content .modal-header {
    padding: 60px 60px 20px; }
    .restaurant-menu-modal-container .modal-content .modal-header .modal-title {
      font-size: 38px;
      font-weight: 700; }
  .restaurant-menu-modal-container .modal-content .modal-body {
    padding: 20px 60px 40px; }
    .restaurant-menu-modal-container .modal-content .modal-body .body-container {
      display: block;
      width: 100%; }
      .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories {
        width: 100%; }
        .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories p {
          color: #f36e21;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 1px; }
        .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories {
          display: flex;
          width: 100%;
          height: 100%;
          flex-wrap: wrap; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add {
            width: 30%;
            margin-right: 30px; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active {
            width: 30%;
            margin-right: 30px; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .btn-group {
              display: flex;
              justify-content: space-between;
              width: 100%; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active input {
              width: 130px;
              margin-right: 4px; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category {
            width: 30%;
            margin-right: 30px;
            background: #efefef;
            border: 1px solid black; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category p {
              color: black; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .edit {
              display: none; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .trash {
              display: none; }
      .restaurant-menu-modal-container .modal-content .modal-body .body-container .line {
        background-color: #f36e21;
        width: 100%;
        height: 1px; }
      .restaurant-menu-modal-container .modal-content .modal-body .body-container .products {
        width: 100%; }
        .restaurant-menu-modal-container .modal-content .modal-body .body-container .products p {
          color: #f36e21;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 1px; }
        .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products {
          display: flex;
          flex-wrap: wrap; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add {
            width: 30%;
            margin-right: 30px; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active {
            width: 30%;
            margin-right: 30px; }
          .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category {
            width: 30%;
            margin-right: 30px;
            background: #efefef;
            border: 1px solid black; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category p {
              color: black; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .edit {
              display: none; }
            .restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .trash {
              display: none; }
      .restaurant-menu-modal-container .modal-content .modal-body .body-container .save-btn {
        display: flex;
        align-items: center; }
        .restaurant-menu-modal-container .modal-content .modal-body .body-container .save-btn p {
          color: #f36e21;
          flex: 1;
          cursor: pointer; }

.mob-restaurant-menu-modal-container .modal-content .modal-header {
  justify-content: center;
  align-items: center; }
  .mob-restaurant-menu-modal-container .modal-content .modal-header .modal-title {
    font-size: 1.3rem;
    font-weight: 700; }

.mob-restaurant-menu-modal-container .modal-content .modal-body {
  padding: 1rem;
  display: flex; }
  .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container {
    display: block;
    width: 100%; }
    .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories {
      width: 100%; }
      .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories p {
        color: #f36e21;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 1px; }
      .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories {
        width: 100%;
        height: 100%;
        flex-wrap: wrap; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .add {
          width: 100%; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active {
          width: 100%; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active .btn-group {
            display: flex;
            justify-content: space-between;
            width: 100%; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .active input {
            width: 13rem;
            margin-right: 4px;
            margin-left: 0.5rem; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category {
          width: 100%;
          margin-right: 30px;
          background: #efefef;
          border: 1px solid black; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category p {
            color: black; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .edit {
            display: none; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .categories .add-categories .category .trash {
            display: none; }
    .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products {
      width: 100%;
      margin-top: 2rem; }
      .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products p {
        color: #f36e21;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 1px; }
      .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products {
        display: flex;
        flex-wrap: wrap; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .add {
          width: 100%; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .active {
          width: 100%; }
        .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category {
          width: 100%;
          background: #efefef;
          border: 1px solid black; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category p {
            color: black; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .edit {
            display: none; }
          .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .products .add-products .category .trash {
            display: none; }
    .mob-restaurant-menu-modal-container .modal-content .modal-body .body-container .save-btn {
      text-align: center; }
