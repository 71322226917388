.change-password-modal .modal-content .modal-body .body-container .change-bottom .btn {
  width: 180px;
  height: 45px;
  background-color: #f78e1e;
  border: none;
  border-radius: 9px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: none; }

.change-password-modal .modal-content .modal-body .body-container .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%; }
  .change-password-modal .modal-content .modal-body .body-container .input-field .common-input input {
    height: 57px;
    width: 100%;
    border-radius: 10px;
    border-color: #414042;
    padding-left: 22px;
    border: 1px solid #414042;
    outline: none; }

.mob-change-password-modal .modal-content .modal-body .body-container .change-bottom .btn {
  background-color: #f78e1e;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 1rem;
  box-shadow: none;
  padding: 0.5rem 3rem;
  margin-bottom: 0;
  min-width: 13rem; }

.mob-change-password-modal .modal-content .modal-body .body-container .input-field .common-input {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.5rem 0; }
  .mob-change-password-modal .modal-content .modal-body .body-container .input-field .common-input input {
    width: 100%;
    border-radius: 0.25rem;
    border-color: #414042;
    border: 1px solid #414042;
    outline: none;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem; }

.change-password-modal {
  max-width: 60% !important;
  margin-top: 116px !important; }
  .change-password-modal .modal-content .modal-header {
    padding: 40px 60px 40px; }
    .change-password-modal .modal-content .modal-header .modal-title {
      font-size: 38px;
      font-weight: 700; }
  .change-password-modal .modal-content .modal-body {
    padding: 20px 60px 40px; }
    .change-password-modal .modal-content .modal-body .body-container {
      display: block;
      width: 100%; }
      .change-password-modal .modal-content .modal-body .body-container .input-field .common-input {
        padding: 10px;
        width: 80%; }
      .change-password-modal .modal-content .modal-body .body-container .change-bottom {
        display: flex;
        justify-content: space-between;
        padding: 20px 0; }
        .change-password-modal .modal-content .modal-body .body-container .change-bottom p {
          color: #f78e1e;
          cursor: pointer; }
        .change-password-modal .modal-content .modal-body .body-container .change-bottom .change-btn {
          width: 230px;
          background-color: #f36e21; }

.mob-change-password-modal .modal-content .modal-header {
  justify-content: center;
  align-items: center; }
  .mob-change-password-modal .modal-content .modal-header .modal-title {
    font-size: 1.3rem;
    font-weight: 700; }

.mob-change-password-modal .modal-content .modal-body {
  padding: 1rem;
  display: flex; }
  .mob-change-password-modal .modal-content .modal-body .body-container {
    display: block;
    width: 100%; }
    .mob-change-password-modal .modal-content .modal-body .body-container .input-field .common-input {
      padding: 10px; }
    .mob-change-password-modal .modal-content .modal-body .body-container .change-bottom {
      justify-content: center;
      display: flex; }
      .mob-change-password-modal .modal-content .modal-body .body-container .change-bottom .change-btn {
        width: 260px;
        background-color: #f36e21; }
