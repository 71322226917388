.profile-preview {
  background-color: #000; }

.profile-preview .profile .background {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: -12px;
  border-radius: 32px;
  opacity: 0.1;
  z-index: 22;
  pointer-events: none; }

.profile-preview .profile .profile-photo {
  height: fit-content;
  display: flex; }

.profile-preview .profile .profile-photo img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #0092b9;
  justify-content: center;
  margin: auto;
  margin-top: 30px; }

.profile-preview .profile .display-name {
  height: 33px; }

.profile-preview .profile .display-name p {
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 5px 0px;
  margin-bottom: 0px; }

.profile-preview .profile .description {
  padding: 14px;
  overflow-y: auto;
  margin-top: 40%; }

@media screen and (max-height: 815px) {
  .profile-preview .profile .description {
    margin-top: 25%; }
}

@media screen and (max-height: 680px) {
  .profile-preview .profile .description {
    margin-top: 20%; }
}
 
.profile-preview .profile .description p {
  text-align: center;
  color: white;
  font-size: 9px;
  word-break: break-all; }

.profile-preview {
  /* background-image: url("../../assets/images/handshake.jpg"); */ }

.profile-preview .profile .profile-photo > img {
  width: 20vh;
  height: 20vh; }

.profile-preview .profile .display-name > p {
  font-size: 25px;
  margin: 0;
  padding: 0; }

.profile-preview .profile .description > p {
  font-size: 14px;
  font-weight: bold; }

.profile-preview .profile .contact-info .user-input {
  height: 45px;
  border-radius: 6px;
  border-color: #414042;
  border: 1px solid #414042;
  outline: none;
  width: 95%;
  text-align: center;
  font-size: 13px; }

.profile-preview .profile .contact-info {
  text-align: center; }

.profile-preview .profile .submit {
  text-align: center; }

.profile-preview .profile .submit > button {
  width: 95%;
  height: 50px;
  background-color: #f36e21;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 25px 0px; }

.profile-preview .stamps {
  color: #fff;
  text-align: center;
  display: inline-block;
  padding-block: 80px; }

.profile-preview .stamps .stamp-heading > h4 {
  color: white;
  font-weight: bold; }

.profile-preview .stamps .stamp-cards > img {
  margin: 10px; }

.profile-preview .stamps .stamp-cards {
  margin-block: 30px; }

.profile-preview .stamps .stamp-count > h4 {
  color: #f58113;
  margin: 0; }

.profile-preview .stamps .stamp-count > h1 {
  color: #f58113;
  font-size: 80px;
  font-weight: 900;
  margin: 0; }

.profile-preview .profile .submit > button:active {
  color: #fff;
  background-color: #f58113;
  border-color: #f58113; }

.event-description {
  font-weight: normal !important;
  padding: 0 !important;
  margin-bottom: 8px !important; }

@media screen and (min-width: 1000px) {
  .profile-preview .profile .profile-photo {
    height: fit-content; }

  .profile-preview .profile .description {
    margin-top: 0;}

  .profile-preview .profile .profile-photo > img {
    width: 16vh;
    height: 16vh; }
} 