/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
src: local('Montserrat'), url('Montserrat-Regular.woff') format('woff'),
    url('Montserrat-Regular.ttf') format('truetype');
}

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 300;
src: local('Montserrat'), url('Montserrat-Light.woff') format('woff'),
    url('Montserrat-Light.ttf') format('truetype');
}

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
src: local('Montserrat'), url('Montserrat-Medium.woff') format('woff'),
    url('Montserrat-Medium.ttf') format('truetype');
}

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
src: local('Montserrat'), url('Montserrat-SemiBold.woff') format('woff'),
    url('Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: local('Montserrat'), url('Montserrat-Bold.woff') format('woff'),
    url('Montserrat-Bold.ttf') format('truetype');
}

@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 900;
src: local('Montserrat'), url('Montserrat-ExtraBold.woff') format('woff'),
    url('Montserrat-ExtraBold.ttf') format('truetype');
}
