.homepage .top-section {
  position: relative; }
  .homepage .top-section .home-image {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    object-fit: cover;
    pointer-events: none; }

.homepage .home {
  position: relative;
  width: 100%;
  height: 540px;
  display: flex; }
  .homepage .home .home-title-container {
    padding: 15px 0px;
    flex: 0 0 50%; }
    .homepage .home .home-title-container .title {
      padding: 10px 0px;
      font-size: 50px;
      color: #414042;
      font-weight: 900;
      margin-bottom: 1rem; }
    .homepage .home .home-title-container .description {
      padding: 10px 0px;
      font-size: 21px;
      color: #f36e21;
      font-weight: 600;
      text-align: justify;
      margin-bottom: 38px; }
    .homepage .home .home-title-container .button {
      background: #f36e21;
      width: 100%;
      height: 83px;
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 1rem;
      border-radius: 10px;
      border-color: black;
      letter-spacing: 1px; }
  .homepage .home .qrcode {
    padding: 30px 0px;
    width: 100%; }
    .homepage .home .qrcode img {
      width: 435px !important;
      height: 435px !important;
      float: right;
      opacity: 0.8; }

.homepage .how-home {
  position: relative;
  width: 100%;
  height: 520px; }
  .homepage .how-home .how-title {
    padding: 80px 0px 20px 0px;
    color: #f36e21; }
    .homepage .how-home .how-title h1 {
      font-weight: 800; }
  .homepage .how-home .how-description {
    display: flex; }
    .homepage .how-home .how-description .description-section {
      flex: 1;
      padding: 30px 0px;
      align-items: center; }
      .homepage .how-home .how-description .description-section .number {
        color: #f36e21;
        font-weight: 900;
        font-size: 64px;
        padding: 0px 20px 0px 0px; }
      .homepage .how-home .how-description .description-section h1 {
        margin-bottom: 0px;
        color: #f36e21;
        font-weight: 600;
        font-size: 22px;
        display: flex;
        align-items: center; }
    .homepage .how-home .how-description .description-sub {
      color: black;
      font-size: 17px;
      padding: 10px 20px 0px 0px; }

.homepage .mid-home {
  padding: 55px 0px 50px 0px;
  position: relative;
  width: 100%;
  background: #efefef;
  text-align: center; }
  .homepage .mid-home p {
    color: #f36e21;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 3px; }
  .homepage .mid-home h1 {
    font-weight: 900;
    color: #414042;
    margin-bottom: 20px; }
  .homepage .mid-home span {
    font-size: 16px;
    font-weight: 500;
    color: #414042; }

.homepage .bottom-home-wrapper {
  position: relative; }
  .homepage .bottom-home-wrapper .bottom-home {
    padding: 0px;
    position: relative;
    width: 100%;
    height: 650px;
    display: flex;
    background-color: transparent; }
    .homepage .bottom-home-wrapper .bottom-home .restaurant-image {
      flex: 50%;
      width: 50%;
      height: 100%;
      position: relative; }
      .homepage .bottom-home-wrapper .bottom-home .restaurant-image .fade {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.6; }
      .homepage .bottom-home-wrapper .bottom-home .restaurant-image .restaurant {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .homepage .bottom-home-wrapper .bottom-home .restaurant-image .hipscan {
        text-align: center;
        padding: 11%;
        position: absolute;
        top: 20px; }
        .homepage .bottom-home-wrapper .bottom-home .restaurant-image .hipscan h1 {
          color: #f36e21;
          font-weight: bold; }
        .homepage .bottom-home-wrapper .bottom-home .restaurant-image .hipscan p {
          color: white;
          margin-top: 30px;
          font-size: 1.1rem; }
    .homepage .bottom-home-wrapper .bottom-home .retail-image {
      flex: 50%;
      width: 50%;
      height: 100%;
      position: relative; }
      .homepage .bottom-home-wrapper .bottom-home .retail-image .fade {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.6; }
      .homepage .bottom-home-wrapper .bottom-home .retail-image .retail {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .homepage .bottom-home-wrapper .bottom-home .retail-image .hipscan {
        text-align: center;
        padding: 11%;
        position: absolute;
        top: 20px; }
        .homepage .bottom-home-wrapper .bottom-home .retail-image .hipscan h1 {
          color: #f36e21;
          font-weight: bold; }
        .homepage .bottom-home-wrapper .bottom-home .retail-image .hipscan p {
          color: white;
          margin-top: 30px;
          font-size: 1.1rem; }
  .homepage .bottom-home-wrapper .aa-btn {
    position: absolute;
    bottom: 100px;
    margin: auto;
    color: white;
    background-color: #f36e21;
    outline: none;
    border: none;
    width: 44%;
    height: 84px;
    font-size: 1.3rem;
    font-weight: 600;
    left: 28%;
    border-radius: 8px;
    letter-spacing: 2px; }

.mob .mob-top-section {
  position: relative;
  padding-bottom: 1.5rem; }
  .mob .mob-top-section .home-image {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    object-fit: cover;
    pointer-events: none; }
  .mob .mob-top-section .home-title-container .title {
    font-weight: 800;
    font-size: 1.7rem;
    text-align: center; }
  .mob .mob-top-section .home-title-container .description {
    color: #f36e21;
    font-weight: 600;
    text-align: center;
    font-size: 0.73rem; }
  .mob .mob-top-section .home-title-container .button {
    background: #f36e21;
    width: 100%;
    font-weight: 600;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border-color: #f36e21;
    letter-spacing: 1px;
    font-size: 0.7rem; }

.mob .how-home {
  position: relative;
  width: 100%;
  padding-bottom: 1.5rem; }
  .mob .how-home .how-title {
    color: #f36e21;
    text-align: center; }
    .mob .how-home .how-title h1 {
      font-weight: 800;
      font-size: 1.6rem;
      padding: 1.6rem 0; }
  .mob .how-home .how-description .description-section {
    flex: 1;
    align-items: center; }
    .mob .how-home .how-description .description-section .number {
      color: #f36e21;
      font-weight: 900;
      font-size: 64px;
      font-size: 2rem;
      padding-right: 1rem; }
    .mob .how-home .how-description .description-section h1 {
      margin-bottom: 0px;
      color: #f36e21;
      font-weight: 600;
      display: flex;
      align-items: center;
      font-size: 1rem; }
  .mob .how-home .how-description .description-sub {
    color: black;
    font-size: 0.78rem;
    margin-top: 0.2rem;
    margin-bottom: 2rem; }

.mob .mid-home {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
  background: #efefef;
  text-align: center; }
  .mob .mid-home p {
    color: #f36e21;
    font-weight: 600;
    letter-spacing: 3px; }
  .mob .mid-home h1 {
    font-weight: 900;
    color: #414042;
    margin-bottom: 1rem;
    font-size: 1.4rem; }
  .mob .mid-home span {
    font-size: 0.72rem;
    font-weight: 500;
    color: #414042; }

.mob .restaurant-image, .mob .retail-image {
  position: relative; }
  .mob .restaurant-image .fade, .mob .retail-image .fade {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6; }
  .mob .restaurant-image .restaurant, .mob .restaurant-image .retail, .mob .retail-image .restaurant, .mob .retail-image .retail {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .mob .restaurant-image .hipscan, .mob .retail-image .hipscan {
    text-align: center;
    position: absolute;
    top: 0; }
    .mob .restaurant-image .hipscan h1, .mob .retail-image .hipscan h1 {
      color: #f36e21;
      font-weight: bold;
      font-size: 1.5rem;
      padding: 2.4rem 0;
      margin-bottom: 0; }
    .mob .restaurant-image .hipscan p, .mob .retail-image .hipscan p {
      color: white;
      font-size: 0.71rem;
      padding: 0 1rem; }

.mob .retail-image {
  height: 19rem; }
  .mob .retail-image .aa-btn {
    color: white;
    background-color: #f36e21;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: .25rem;
    letter-spacing: 2px;
    padding: 1rem; }
