.login-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .login-home .login-section {
    flex: 1;
    position: relative;
    height: 100%; }
    .login-home .login-section .login-image {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.1;
      object-fit: cover; }
    .login-home .login-section .container .home {
      top: 50px;
      position: relative;
      background-color: #000000bf;
      padding: 55px 294px; }
      .login-home .login-section .container .home h1 {
        font-weight: 800;
        color: white;
        margin-bottom: 2rem; }
      .login-home .login-section .container .home .info-input .form-group {
        padding: 4px 0px; }
        .login-home .login-section .container .home .info-input .form-group .form-control {
          height: 55px;
          border-radius: 8px; }
      .login-home .login-section .container .home .login-btn {
        width: 100%;
        height: 75px;
        border-radius: 9px;
        font-size: 17px;
        background-color: #f36e21;
        font-weight: 500;
        letter-spacing: 2px;
        margin-top: 9px;
        border: none;
        margin-bottom: 50px; }
      .login-home .login-section .container .home .login-title {
        display: flex;
        align-items: center; }
        .login-home .login-section .container .home .login-title p {
          flex: 1;
          color: white;
          padding: 15px 0px;
          font-size: 20px;
          letter-spacing: 2px;
          margin-bottom: 0px; }
        .login-home .login-section .container .home .login-title .line {
          width: 250px;
          height: 2px;
          background-color: white; }
      .login-home .login-section .container .home .social-login {
        display: flex; }
        .login-home .login-section .container .home .social-login .google-login {
          height: 55px;
          flex: 1;
          font-size: 13px;
          font-weight: 500;
          background-color: #f78e1e;
          padding: 15px;
          margin: 15px 12px 15px 0px;
          border-radius: 9px;
          border: none; }
        .login-home .login-section .container .home .social-login .facebook-login {
          height: 55px;
          flex: 1;
          font-size: 13px;
          font-weight: 500;
          background-color: #f78e1e;
          padding: 15px;
          margin: 15px 0px 15px 12px;
          border-radius: 9px;
          border: none; }
    .login-home .login-section #high {
      height: 789px; }

.mob-login-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .mob-login-home .login-section {
    flex: 1;
    position: relative;
    height: 100%; }
    .mob-login-home .login-section .login-image {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.1;
      object-fit: cover; }
    .mob-login-home .login-section .container .home {
      position: relative;
      background-color: #000000bf;
      height: 100vh; }
      .mob-login-home .login-section .container .home h1 {
        font-weight: 800;
        color: white;
        margin-bottom: 2rem;
        font-size: 1.7rem;
        width: 100%;
        text-align: center; }
      .mob-login-home .login-section .container .home .info-input .form-group {
        padding: 4px 0px; }
        .mob-login-home .login-section .container .home .info-input .form-group .form-control {
          padding: 1rem 0.75rem;
          font-size: 0.7rem; }
      .mob-login-home .login-section .container .home .login-btn {
        font-weight: 500;
        letter-spacing: 2px;
        margin-top: 9px;
        border: none;
        margin-bottom: 50px;
        width: 100%;
        font-size: 0.8rem;
        padding: 0.8rem 0;
        background-color: #f36e21;
        font-weight: 500;
        letter-spacing: 2px;
        border: none; }
      .mob-login-home .login-section .container .home .login-title {
        display: flex;
        align-items: center;
        text-align: center; }
        .mob-login-home .login-section .container .home .login-title p {
          flex: 1;
          color: white;
          padding: 15px 0px;
          font-size: 20px;
          letter-spacing: 2px;
          margin-bottom: 0px; }
        .mob-login-home .login-section .container .home .login-title .line {
          width: 250px;
          height: 2px;
          background-color: white; }
      .mob-login-home .login-section .container .home .social-login {
        display: flex;
        margin-bottom: 2rem; }
        .mob-login-home .login-section .container .home .social-login .google-login,
        .mob-login-home .login-section .container .home .social-login .facebook-login {
          height: 2.7rem;
          flex: 1;
          font-size: 0.5rem;
          font-weight: 500;
          background-color: #f78e1e;
          padding: 15px;
          margin: 0.5rem;
          border-radius: 9px;
          border: none; }
      .mob-login-home .login-section .container .home .footerbar {
        height: auto; }
    .mob-login-home .login-section #high {
      height: 100vh; }
