.header2bar {
  position: relative;
  width: 100%;
  height: 100px;
  top: 5px;
  padding: 25px 80px; }
  .header2bar .header {
    display: flex;
    align-items: center; }
    .header2bar .header .logo {
      width: 150px;
      height: 50px;
      flex: 1; }
      .header2bar .header .logo img {
        display: block;
        width: 150px;
        height: 100%; }
    .header2bar .header .user-name {
      cursor: pointer;
      color: #f36e21; }
      .header2bar .header .user-name a {
        color: #f36e21;
        text-decoration: none; }
        .header2bar .header .user-name a .drop-icon {
          margin-left: 10px; }
    .header2bar .header p {
      color: #f36e21;
      margin-bottom: 0px;
      font-weight: bold;
      margin: 0px 20px 0px 40px;
      cursor: pointer; }
    .header2bar .header .logout .logout-btn {
      width: 100px;
      height: 50px;
      color: #f36e21;
      border: none;
      background: transparent; }
    .header2bar .header .notification {
      position: relative; }
      .header2bar .header .notification .bell-icon {
        width: 20px; }
      .header2bar .header .notification .notification-num {
        left: 12px;
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        top: 10px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        background-color: #f36e21;
        color: #ffffff;
        cursor: pointer; }
    .header2bar .header .bell-notify {
      margin-left: 20px; }
      .header2bar .header .bell-notify .dropdown-toggle {
        background: none;
        border: none;
        padding: 0;
        box-shadow: none !important; }
        .header2bar .header .bell-notify .dropdown-toggle .notification {
          position: relative; }
          .header2bar .header .bell-notify .dropdown-toggle .notification .bell-icon {
            width: 20px; }
          .header2bar .header .bell-notify .dropdown-toggle .notification .notification-num {
            left: 12px;
            position: absolute;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            top: 10px;
            font-size: 11px;
            font-weight: 700;
            text-align: center;
            background-color: #f36e21;
            color: #ffffff;
            cursor: pointer; }
        .header2bar .header .bell-notify .dropdown-toggle::after {
          display: none; }
  .header2bar .underline {
    width: 100%;
    height: 2px;
    background-color: #414042; }

.mob-header2bar {
  position: relative;
  width: 100%;
  top: 5px;
  padding: 1rem; }
  .mob-header2bar .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .mob-header2bar .header .goto {
      color: #f36e21;
      z-index: 20; }
    .mob-header2bar .header .logo {
      width: 100%;
      position: absolute;
      text-align: center;
      justify-content: center;
      display: flex;
      z-index: 10; }
      .mob-header2bar .header .logo img {
        display: block;
        width: 5rem;
        height: 100%; }
    .mob-header2bar .header .user-name {
      cursor: pointer;
      color: #f36e21; }
    .mob-header2bar .header p {
      color: #f36e21;
      margin-bottom: 0px;
      font-weight: bold;
      margin: 0px 20px 0px 40px;
      cursor: pointer; }
    .mob-header2bar .header .logout .logout-btn {
      width: 100px;
      height: 50px;
      color: #f36e21;
      border: none;
      background: transparent; }
  .mob-header2bar .underline {
    width: 100%;
    height: 2px;
    background-color: #414042; }

.mob-menu-modal-container .modal-content .modal-header {
  padding: 1rem; }

.mob-menu-modal-container .menu-list {
  display: block;
  text-align: center; }
  .mob-menu-modal-container .menu-list .backAdmin {
    margin: 0 !important; }
  .mob-menu-modal-container .menu-list .menu-item-container {
    width: 100%;
    margin: 1rem 0; }
    .mob-menu-modal-container .menu-list .menu-item-container a {
      color: #f36e21;
      text-decoration: none;
      font-weight: bold; }
    .mob-menu-modal-container .menu-list .menu-item-container .notification {
      margin: 0 10px 0 20px;
      position: relative; }
      .mob-menu-modal-container .menu-list .menu-item-container .notification .bell-icon {
        width: 20px; }
      .mob-menu-modal-container .menu-list .menu-item-container .notification .notification-num {
        left: 51%;
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        top: 10px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        background-color: #f36e21;
        color: #ffffff;
        cursor: pointer; }

.ant-popover {
  width: 280px !important; }
