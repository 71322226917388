.footerbar {
  position: relative;
  background-color: black;
  width: 100%;
  padding: 25px 30px 5px;
  box-sizing: border-box; }
  .footerbar .footer {
    display: flex;
    padding: 25px 0px;
    align-items: center;
    height: 100%;
    max-width: 1330px;
    margin: 0 auto; }
    .footerbar .footer a {
      font-size: 17px;
      font-weight: 600;
      color: #fff; }
      .footerbar .footer a:hover {
        text-decoration: none; }
    .footerbar .footer .header-wrapper {
      display: flex;
      width: 100%;
      color: white;
      justify-content: space-between;
      align-items: center; }
      .footerbar .footer .header-wrapper .logo-footer img {
        width: 260px; }
      .footerbar .footer .header-wrapper .icons-and-links {
        padding: 0 15px 0 77px; }
      .footerbar .footer .header-wrapper .social-icon {
        display: flex;
        flex: 50%;
        justify-content: center;
        margin-bottom: 35px; }
        .footerbar .footer .header-wrapper .social-icon .social {
          width: 31px;
          height: 31px;
          background: #f39f21f2;
          border-radius: 20px;
          color: black;
          margin: 0px 24px;
          display: flex;
          justify-content: center;
          align-items: center; }
          .footerbar .footer .header-wrapper .social-icon .social svg {
            width: 18px;
            height: 18px; }
      .footerbar .footer .header-wrapper .links .dot {
        font-weight: 600;
        margin: 0 11px; }
      .footerbar .footer .header-wrapper .terms {
        text-align: right; }
      .footerbar .footer .header-wrapper .terms-and-condition {
        margin-bottom: 35px; }
        .footerbar .footer .header-wrapper .terms-and-condition span {
          margin: 0 10px;
          font-size: 17px; }
      .footerbar .footer .header-wrapper .copyright {
        font-weight: 600;
        font-size: 17px; }
  @media (max-width: 1200px) {
    .footerbar .footer {
      padding: 25px 0px 15px; }
      .footerbar .footer .header-wrapper {
        align-items: normal; }
        .footerbar .footer .header-wrapper .links {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .footerbar .footer .header-wrapper .links a {
            margin-bottom: 10px; }
          .footerbar .footer .header-wrapper .links .dot {
            display: none; } }
  @media (max-width: 980px) {
    .footerbar .footer {
      padding: 25px 0px 15px; }
      .footerbar .footer .header-wrapper .terms-and-condition {
        margin-bottom: 25px; }
        .footerbar .footer .header-wrapper .terms-and-condition a {
          display: block; }
        .footerbar .footer .header-wrapper .terms-and-condition span {
          display: none; }
      .footerbar .footer .header-wrapper .terms {
        text-align: center; }
      .footerbar .footer .header-wrapper .social-icon .social {
        margin: 0px 20px; } }
  @media (max-width: 780px) {
    .footerbar .footer {
      padding: 25px 0px 15px; }
      .footerbar .footer .header-wrapper .logo-footer img {
        width: 200px; }
      .footerbar .footer .header-wrapper .icons-and-links {
        padding: 0 5px; } }
  @media (max-width: 680px) {
    .footerbar .footer {
      padding: 25px 0px 15px; }
      .footerbar .footer .header-wrapper {
        display: block; }
        .footerbar .footer .header-wrapper .logo-footer {
          text-align: center;
          margin-bottom: 15px; }
          .footerbar .footer .header-wrapper .logo-footer img {
            width: 260px; } }
